import React, {FC, useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import './style/tailwind-postcss-output.css';
// import './style/index.css';
import App from './App';
import {MoralisProvider} from "react-moralis";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import './lang/i18n';
import reactToWebComponent from 'react-to-webcomponent';


Amplify.configure(awsExports);

interface IIndex {
    env ?: 'dev' | 'live'
}


const Index : FC<IIndex> = ({env = 'live'}) => {




    return (
        <React.StrictMode>
            <MoralisProvider
                appId={env === 'live' ? 'AFKzdV4bt9Q0svxnhF7z8M4hEoLUQLUiHkpdbHfm' : '8JjKQXcUhw1W8CPFdHGuLkK6KLAjTsNp5dZhtZcU'}
                serverUrl={env === 'live' ? 'https://3ksnkbyqox9c.usemoralis.com:2053/server' : 'https://9oomlov5gg5z.usemoralis.com:2053/server'}>
                <App env={env}/>
            </MoralisProvider>
        </React.StrictMode>
    )
}


export default Index


const ArgosId = reactToWebComponent(Index as React.FunctionComponent<IIndex>, React as any, ReactDOM as any , {
    props : ['env']
});
customElements.define('argos-central', ArgosId as any)