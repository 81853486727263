import {ReactNode} from "react";

export interface ICommonType {

}

export interface IError {
    error: boolean,
    msg: null | string
}


export interface IApiArgosId {
    accounts: string[],
    createTime: Date,
    ethAddress: string
    objectId: string
    statusCode: number
    updateTime: Date,
    userName: string
    userId?: string,
    consentMarketing : boolean
}


export enum IIdStatus {
    notApproved = 'not approved',
    expired = 'expired',
    approved = 'approved',
    pending = 'pending',
    rejected = 'rejected',
    incomplete = 'incomplete'
}

export interface ICertificate {
    statusCode: number | null
    idStatus: IIdStatus
    message ?: string
    argosId ?: string
    uuid : string
    Item : any
}

export interface IUser {
    objectId : string,
    id: string,
    className: string,
    argosId ?: string,
    uuid : string,
    consentMarketing : boolean
}

export interface IWallet {
    id ?: string
    address : string,
    chainId : string,
    coinName :string,
    walletName : string,
    coinTicker : string,
}

export interface IActivityCoins {
    option_bscAddress: boolean
    option_ethAddress: boolean
    option_klayAddress: boolean
    option_maticAddress: boolean
    option_solAddress: boolean
    'option_avaxAddress' : boolean,
    'option_arbAddress' : boolean,
    'option_opAddress' : boolean,
    'option_ftmAddress' : boolean,
    'option_croAddress' : boolean,
    'option_oneAddress' : boolean,
    'option_etcAddress' : boolean,
    'option_htAddress' : boolean,
}




export interface IAcceptCoinTable {
    network : string
    ticker : string
    fullName : string
}


export interface ISettingElementItem {
    type: "subRouter" | "link" | string
    href?: string
    value : string
    subRouter: ISubRouter | string
}


export interface ISettingElement {
    title : string,
    item : ISettingElementItem[]
}


export interface IHistory {
    authTime : string
    projectId : string
    projectName : string
}

export type ISubRouter =
    "face"
    | "id"
    | "authHistory"
    | 'language'
    | 'serviceAgreement'
    | 'termsOfService'
    | 'privacyPolicy'
    | 'ReceiveAdvertisingInformation'
    | 'deleteArgosID'
    | ''
    | string

export type IMainRouter = "user" | "wallet" | "setting" | "firstVisit" | "changeNetwork"



export type ChainId = '0x38' | "0x1" | '0x89' | '0x2019' | 'sol' | '' | 'btc' | '0xA86A' | '0xA4B1' | '0xA' | '0xFA' | '0x19' | '0x63564C40' | '0x3D' | '0x80'
export type CoinName = 'Binance Smart Chain Mainnet' | 'Ethereum' | 'Polygon Mainnet' | 'Klaytn Mainnet Cypress' | 'Solana' | "Bitcoin" | "Avalanche Mainnet C-Chain" |"" | "Arbitrum One" | 'Optimism' | 'Fantom Mainnet by Ankr RPC' | 'Cronos' | 'Harmony Mainnet Shard 0' | 'Ethereum Classic Mainnet' | 'Huobi ECO Chain Mainnet'
export type CoinTicker = 'ETH' | 'BNB' | 'MATIC' | 'KLAY' | 'sol' | 'btc' | 'AVAX' |'' | 'FTM' | 'CRO' | 'ONE' | 'ETC' | "HT"
export type ShortCoinName = 'Binance Smart Chain' | 'Ethereum' | 'Polygon' | 'Klaytn' | 'Solana' | "Bitcoin" | "Avalanche" |"" | "Arbitrum" | 'Optimism' | "Fantom" | 'Cronos' | 'Harmony' | 'Ethereum Classic' | "Huobi ECO Chain"
export interface ICurrentSelectNetwork {
    chainId: ChainId,
    name: CoinName
    logo: ReactNode | null
    networkUrl: string,
    symbol : CoinTicker,
    blockExplorerUrls : string,
    nativeCurrency: {
        name: string,
        symbol: string,
        decimals: 18 | 8
    },
    shortName : ShortCoinName
}
