import React from 'react';


interface SmallLoading {
    borderRightColor ?: string
    borderColor ?: string
}

const SmallLoading : React.FC<SmallLoading> = ({borderRightColor = 'skyblue'}) => {
    return (
        <>
            <div className="spinner-2" style={{borderRightColor}}></div>
        </>
    )
}
export default SmallLoading
