import {ICurrentSelectNetwork, ISubRouter} from "./types/type";
import {useTranslation} from "react-i18next";
import MemoCoinImage from "./components/CoinImage";
import eth from "./assets/ethereum.svg";
import binace from "./assets/binance.svg";
import polygon from "./assets/polygon.svg";
import klaytn from "./assets/klaytn.svg";
import solana from "./assets/solana.svg";
import avalanche from './assets/avalanche.svg';
import arbitrum from './assets/arbitrum.svg';
import optimism from './assets/optimism.svg';
import fantom from './assets/fantom.svg';
import cronos from './assets/cronos.png';
import harmony from './assets/harmony.svg';
import huobi from './assets/huobi.svg';
import React, {CSSProperties} from "react";

export const AID_USER_ID = 'aidUserId'
export const API_ARGOS_ID = 'apiArgosID'

export const COIN_TICKER_AND_FULL_NAME_LIST = [
    {
        network: '1',
        ticker: 'eth',
        fullName: 'Ethereum'
    },
    {
        network: '56',
        ticker: 'bsc',
        fullName: 'Binance Smart Chain'
    },
    {
        network: '137',
        ticker: 'matic',
        fullName: 'Polygon'
    },
    {
        network: 'sol',
        ticker: 'sol',
        fullName: 'Solana'
    },
    {
        network: '8217',
        ticker: 'klay',
        fullName: 'klaytn'
    },
    {
        network : '43114',
        ticker : 'avax',
        fullName: 'Avalanche'
    },
    {
        network : '42161',
        ticker : 'arb',
        fullName: 'Arbitrum One',
    },
    {
        network : '10',
        ticker : "op",
        fullName: 'Optimism'
    },
    {
        network : '250',
        ticker : 'ftm',
        fullName: 'Fantom Opera'
    },
    {
        network : '25',
        ticker : 'cro',
        fullName: 'Cronos'
    },
    {
        network : '1666600000',
        ticker : 'one',
        fullName: 'Harmony'
    },
    {
        network :'61',
        ticker : 'etc',
        fullName: 'Ethereum Classic'
    },
    {
        network : '128',
        ticker : 'ht',
        fullName: 'Huobi ECO Chain'
    }
]


export const SETTING_ELEMENT = [

    {
        title: "Service setting",
        item: [
            {
                type: "subRouter",
                href: "",
                value: "Auth History",
                subRouter: "authHistory"
            },
            // {
            //     type: "subRouter",
            //     href: "",
            //     value: "Language",
            //     subRouter: "language"
            // },
            {
                type: "subRouter",
                href: "",
                value: "Delete ARGOS ID",
                subRouter: "deleteArgosID"
            },

        ]
    },
    {
        title: "Customer support",
        item: [
            {
                type: "link",
                href: "https://argos.notion.site/New-to-Argos-ID-c1576ebaa2db43e5909689d761695de2",
                value: "Announcement",
                subRouter: ""
            },
            // {
            //     type: "link",
            //     href: "",
            //     value: "FAQ",
            //     subRouter: ""
            // },
            {
                type: "link",
                href: "https://support.argoskyc.com/hc/en-us/requests/new",
                value: "CS Center",
                subRouter: ""
            }
        ]
    },
    {
        title: "Service Policy",
        item:
            [
                {
                    type: "subRouter",
                    href: "",
                    value: "Terms and Service Agreement",
                    subRouter: "serviceAgreement"
                },
                {
                    type: "link",
                    href: "https://argoskyc.com/about_us",
                    value: "Company info",
                    subRouter: ""
                }
            ]
    }
]


export const SERVICE_AGREEMENT_REQUIRED =
    [
        {
            title: "Required",
            item:
                [
                    {
                        type: "link",
                        href: " https://argos.notion.site/Terms-of-Service-46a23f39bce9449e91ac690166b4ebb0",
                        value: "Terms of Service",
                        subRouter: "termsOfService"
                    },
                    {
                        type: "link",
                        href: "https://argos.notion.site/Privacy-Policy-575349918b42491b9002c1a98b6aaa5c",
                        value: "Privacy policy",
                        subRouter: "privacyPolicy"
                    },
                ]

        }
    ]

export const SERVICE_AGREEMENT_OPTIONAL = [
    {
        title: "Optional",
        item: [
            {
                type: "link",
                href: "https://argos.notion.site/Consent-to-use-of-personal-information-and-receiving-advertisements-dbab9d818622413b800e8be313776812",
                value: "Receive advertising information",
                subRouter: "ReceiveAdvertisingInformation"
            }
        ]
    }
]


const smallLogoCSS: CSSProperties = {width: '30px', height: '30px'}
export const CHAIN_NETWORK_LIST : ICurrentSelectNetwork[] = [
    // {
    //     chainId : "btc",
    //     name : "Bitcoin",
    //     logo : <MemoCoinImage bgColor={'btc-bg-color'} logoSrc={bitcoin} coinName={'bitcoin'} style={smallLogoCSS}/>,
    //     networkUrl : "https://www.blockchain.com",
    //     symbol : 'btc',
    //     blockExplorerUrls : "https://www.blockchain.com",
    //     nativeCurrency : {
    //         name : "btc",
    //         symbol: "btc",
    //         decimals: 8
    //     }
    // },
    {
        chainId: "0x1",
        name: 'Ethereum',
        logo: <MemoCoinImage bgColor={'eth-bg-color'} logoSrc={eth} coinName={'Ethereum'} style={smallLogoCSS}/>,
        networkUrl : "https://cloudflare-eth.com",
        symbol : 'ETH',
        blockExplorerUrls : 'https://cloudflare-eth.com',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        shortName : 'Ethereum'
    },
    {
        chainId: '0x38',
        name: 'Binance Smart Chain Mainnet',
        logo: <MemoCoinImage bgColor={'bsc-bg-color'} logoSrc={binace} coinName={'binance'} style={smallLogoCSS}/>,
        networkUrl : "https://bsc-dataseed1.binance.org",
        symbol : 'BNB',
        blockExplorerUrls : 'https://bscscan.com',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        shortName : 'Binance Smart Chain'
    },
    {
        chainId: '0x89',
        name: 'Polygon Mainnet',
        logo: <MemoCoinImage bgColor={'matic-bg-color'} logoSrc={polygon} coinName={'Polygon'} style={smallLogoCSS}/>,
        networkUrl : "https://polygon-rpc.com",
        symbol : "MATIC",
        blockExplorerUrls : 'https://polygonscan.com/',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18
        },
        shortName : 'Polygon'
    },
    {
        chainId: '0x2019',
        name: "Klaytn Mainnet Cypress",
        logo: <MemoCoinImage bgColor={'klay-bg-color'} logoSrc={klaytn} coinName={'klaytn'} style={smallLogoCSS}/>,
        networkUrl : "https://public-node-api.klaytnapi.com/v1/cypress",
        symbol : "KLAY",
        blockExplorerUrls : 'https://scope.klaytn.com/',
        nativeCurrency: {
            name: 'KLAY',
            symbol: 'KLAY',
            decimals: 18
        },
        shortName : 'Klaytn'
    },
    {
        chainId: 'sol',
        name: "Solana",
        logo: <MemoCoinImage bgColor={'sol-bg-color'} logoSrc={solana} coinName={'solana'} style={smallLogoCSS}/>,
        networkUrl : "https://bsc-dataseed.binance.org",
        symbol : "sol",
        blockExplorerUrls : 'https://scope.klaytn.com/',
        nativeCurrency: {
            name: 'SOL',
            symbol: 'SOL',
            decimals: 18
        },
        shortName : 'Solana'
    },
    {
        chainId : "0xA86A",
        name : "Avalanche Mainnet C-Chain",
        logo : <MemoCoinImage bgColor={'avalanche-bg-color'} logoSrc={avalanche} coinName={'avalanche'} style={smallLogoCSS}/>,
        networkUrl : "https://api.avax.network/ext/bc/C/rpc",
        symbol : "AVAX",
        blockExplorerUrls : "https://snowtrace.io",
        nativeCurrency : {
            name : 'Avalanche',
            symbol: 'AVAX',
            decimals: 18
        },
        shortName : 'Avalanche'
    },
    {
        chainId : "0xA4B1",
        name : "Arbitrum One",
        logo : <MemoCoinImage bgColor={'arbitrum-bg-color'} logoSrc={arbitrum} coinName={'arbitrum'} style={smallLogoCSS}/>,
        networkUrl : "https://arb1.arbitrum.io/rpc",
        symbol : "ETH",
        blockExplorerUrls : "https://arbiscan.io/",
        nativeCurrency : {
            name : 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        shortName : "Arbitrum"
    },
    {
        chainId : "0xA",
        name : "Optimism",
        logo : <MemoCoinImage bgColor={'optimism-bg-color'} logoSrc={optimism} coinName={'optimism'} style={smallLogoCSS}/>,
        networkUrl : "https://mainnet.optimism.io",
        symbol : "ETH",
        blockExplorerUrls : "https://optimistic.etherscan.io",
        nativeCurrency : {
            name : 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        shortName : "Optimism"
    },
    {
        chainId : "0xFA",
        name : "Fantom Mainnet by Ankr RPC",
        logo : <MemoCoinImage bgColor={'fantom-bg-color'} logoSrc={fantom} coinName={'fantom'} style={smallLogoCSS}/>,
        networkUrl : "https://rpc.ankr.com/fantom/",
        symbol : "FTM",
        blockExplorerUrls : "https://ftmscan.com/",
        nativeCurrency : {
            name : 'FTM',
            symbol: 'FTM',
            decimals: 18
        },
        shortName : "Fantom"
    },
    {
        chainId : "0x19",
        name : "Cronos",
        logo : <MemoCoinImage bgColor={'cronos-bg-color'} logoSrc={cronos} coinName={'cronos'} style={smallLogoCSS}/>,
        networkUrl : "https://evm.cronos.org",
        symbol : "CRO",
        blockExplorerUrls : "https://cronoscan.com/",
        nativeCurrency : {
            name : 'CRO',
            symbol: 'CRO',
            decimals: 18
        },
        shortName : "Cronos"
    },
    {
        chainId : "0x63564C40",
        name : "Harmony Mainnet Shard 0",
        logo : <MemoCoinImage bgColor={'bg-white'} logoSrc={harmony} coinName={'cronos'} style={smallLogoCSS}/>,
        networkUrl : "https://api.harmony.one",
        symbol : "ONE",
        blockExplorerUrls: 'https://explorer.harmony.one/',
        nativeCurrency : { name: 'ONE', symbol: 'ONE', decimals: 18 },
        shortName : "Harmony"
    },
    {
        chainId : "0x3D",
        name : "Ethereum Classic Mainnet",
        logo: <MemoCoinImage bgColor={'eth-classic-bg-color'} logoSrc={eth} coinName={'Ethereum'} style={smallLogoCSS}/>,
        networkUrl : "https://www.ethercluster.com/etc",
        symbol : "ETC",
        blockExplorerUrls: 'https://blockscout.com/etc/mainnet/',
        nativeCurrency : { name: 'ETC', symbol: 'ETC', decimals: 18 },
        shortName : "Ethereum Classic"
    },
    {
        chainId : "0x80",
        name : "Huobi ECO Chain Mainnet",
        logo: <MemoCoinImage bgColor={'houbi-bg-color'} logoSrc={huobi} coinName={'Huobi ECO Chain'} style={smallLogoCSS}/>,
        networkUrl : "https://http-mainnet.hecochain.com",
        symbol : "HT",
        blockExplorerUrls: 'https://scan.hecochain.com',
        nativeCurrency : { name: 'HT', symbol: 'HT', decimals: 18 },
        shortName : "Huobi ECO Chain"
    },

]

export const ID_TYPES = {
    "drivers_license" : "Driver License",
    "passport" : "Passport",
    "government_id" : "Government ID",
    "residence_permit" : "Residence Permit",
    "vehicle_registration_certificate" : "Vehicle Registration Certificate",
    "visa" : "VISA",
    "aadhaar" : "AADHAAR",
    "pancard" : "PANCARD",
}

