import React, {useState, useEffect, useMemo, useCallback, Dispatch, SetStateAction,} from 'react';
import {useMoralis} from "react-moralis";
import phantom from '../../assets/phantom.png';
import {ICommonPage} from "../../types/commonModal";
import {
    IAcceptCoinTable,
    IActivityCoins,
    ICurrentSelectNetwork,
    IIdStatus,
    IMainRouter,
    IUser,
    IWallet
} from "../../types/type";
import MoralisType, {Moralis} from "moralis";
import WalletContentsWrapper from "../../components/common/WalletContentsWrapper";
import {API} from "aws-amplify";
import RingsLoaderMemo from "../../components/common/Loader";
import {API_ARGOS_ID, COIN_TICKER_AND_FULL_NAME_LIST} from "../../constant";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WalletList from "../../components/WalletList";
import {faWallet} from "@fortawesome/free-solid-svg-icons";
import {toastError, toastInfo, toastSuccess} from "../../toast";
import SmallLoading from "../../components/common/SmallLoading";
import {flushSync} from "react-dom";
import SupportedWalletsList from "../../components/SupportedWalletsList";
import {useTranslation} from "react-i18next";
import VerifyPersonWallet from "../../components/VerifyPersonWallet";
import {isChrome, isDesktop, isEdge, isFirefox} from "react-device-detect";

interface IMyPage extends ICommonPage {
    goNextStep: (param ?: boolean, forceStep ?: number) => void,

    liveFormResult: IIdStatus | null
    step: number
    alreadyUserId: boolean
    user: IUser
    loading: boolean,
    wallet: IWallet[]
    setWallet: React.Dispatch<React.SetStateAction<IWallet[]>>
    setNextPageAnimation: Dispatch<SetStateAction<boolean>>
    currentSelectNetwork: ICurrentSelectNetwork
    setCurrentSelectNetwork: Dispatch<SetStateAction<ICurrentSelectNetwork>>
    setRouter: Dispatch<SetStateAction<IMainRouter>>
    env: 'dev' | 'live'
}

const Walletes: React.FC<IMyPage> =
    ({

         liveFormResult,
         step,
         alreadyUserId,
         user,
         loading,
         wallet,
         setWallet,
         setNextPageAnimation,
         currentSelectNetwork,
         setCurrentSelectNetwork,
         setRouter,
         env
     }) => {
        const {t} = useTranslation()
        const {authenticate, enableWeb3, web3, Moralis} = useMoralis();
        const [walletLoading, setWalletLoading] = useState<boolean>(false);
        const [flag, setFlag] = useState<boolean>(false);
        /** Wallet 닫았을 때 **/


        const walletDuplicationVerification = (chainId: string, walletAddress: string): IWallet | undefined => {
            return wallet.find((eachWallet) => eachWallet.chainId === chainId && eachWallet.address === walletAddress);
        }


        const updateWalletInfo = async (walletAddress: string, walletName: string) => {
            let chainId: string | number
            const hexChainId: any = await Moralis.getChainId();
            chainId = (parseInt(hexChainId)) + '';
            if (walletName === 'phantom') {
                chainId = 'sol'
            }
            if (walletDuplicationVerification(chainId, walletAddress)) {
                /**ArgosID 에서 중복 입니다 다시 선택해주세요 **/
                return toastError(`${t('wallet-already-certified')}`);
            }
            const coinName = COIN_TICKER_AND_FULL_NAME_LIST.find((item) => item.network === chainId);
            if (!coinName) {
                /**ArgosID 에서 지원하지 않는 코인 입니다 다시 선택해주세요 **/
                return toastError(`${t('coin-not-supported')}`);
            }
            const selectCoinTickersAndFullName = COIN_TICKER_AND_FULL_NAME_LIST.find((item) => item.fullName === coinName.fullName);
            if (!selectCoinTickersAndFullName) {
                /**ArgosID 에서 지원하지 않는 코인 입니다 다시 선택해주세요 **/
                return toastError(`${t('coin-not-supported')}`);
            }
            try {
                const walletInstance: IWallet = {
                    address: walletAddress,
                    chainId,
                    walletName,
                    coinName: selectCoinTickersAndFullName.fullName,
                    coinTicker: selectCoinTickersAndFullName.ticker,
                }
                let params = {
                    body: {
                        userId: user.id,
                        alias: env,
                        requestType: 'update',
                        name: walletInstance.walletName,
                        chainId: walletInstance.chainId,
                        coinTicker: walletInstance.coinTicker,
                        coinName: walletInstance.coinName,
                        address: walletInstance.address
                    },
                };
                const updateResponse = await API.post(API_ARGOS_ID, '/wallet', params)
                if (updateResponse.statusCode !== 200) {
                    return toastError(updateResponse.message)
                }
                if (updateResponse.statusCode === 200) {
                    setWallet((prev) => [...prev, {...walletInstance, id: updateResponse.id}])
                    return toastSuccess(`${t('success-upload-wallet')}`);
                }
            } catch (e) {
                return toastError(`${t('failed-update-wallet')}`);
            }
        }


        const switchNetwork = useCallback(async (currentNetwork : ICurrentSelectNetwork) => {
            try {
                // check if the chain to connect to is installed
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId : currentNetwork.chainId}], // chainId must be in hexadecimal numbers
                });
            } catch (error: any) {
                if (error.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [{
                                chainName: currentNetwork.name,
                                chainId: currentNetwork.chainId,
                                rpcUrls: [currentNetwork.networkUrl],
                                blockExplorerUrls: [currentNetwork.blockExplorerUrls],
                                nativeCurrency : currentNetwork.nativeCurrency
                            }],
                        })
                    } catch (addError : any) {
                        if (addError.message === 'User rejected the request.') return false
                    }
                }
                if (error.message === 'User rejected the request.') return false
            }
        },[])




        const walletLogin = async (redirectURL: string, walletProvider: Moralis.Web3ProviderType = 'metamask') => {
            try {
                if (flag) return;
                setFlag(true)
                if (typeof window.ethereum === 'undefined' && walletProvider === 'metamask') {
                    window.open(redirectURL, "_blank")
                    return toastInfo(t('please-refresh'))
                }

                if (walletProvider === 'metamask') {
                    const switchRes = await switchNetwork(currentSelectNetwork);
                    if (typeof switchRes === 'boolean' && !switchRes) return ;
                }


                const user: Moralis.User<Moralis.Attributes> | undefined = await authenticate({
                    signingMessage: "ARGOS ID Authentication",
                    provider: walletProvider,
                })
                if (user) {
                    setWalletLoading(true)
                    if (!alreadyUserId) {
                        const userObjectId = user.id
                        await setUpdateUser(userObjectId)
                    }
                    const walletAddress = user!.get("ethAddress");
                    const hexChainId: any = await Moralis.getChainId();
                    const chainId = (parseInt(hexChainId)) + '';
                    if (walletDuplicationVerification(chainId, walletAddress)) {
                        return toastError(`${t('wallet-already-certified')}`);
                    }
                    await updateWalletInfo(walletAddress, walletProvider)
                }
            } catch (e) {
                console.log("Catch the Error :: ", e)
                return toastError(`${t('login-error')}`);
            } finally {
                setWalletLoading(false)
                setFlag(false);
            }
        }




        const phantomLogin = async () => {
            try {
                setFlag(true)
                if (flag) return;

                if (!window.phantom) {
                    setFlag(prev => !prev)
                    window.open("https://phantom.app/download", "_blank")
                    return toastInfo(t('please-refresh'))
                }

                const phantomConnectorUser: MoralisType.User | undefined = await authenticate({
                    signingMessage: "Argos ID Authentication",
                    type: "sol"
                });

                if (phantomConnectorUser) {
                    setWalletLoading(true);
                    if (!alreadyUserId) {
                        const phantomUserObjectId = phantomConnectorUser.id
                        await setUpdateUser(phantomUserObjectId)
                    }
                    const phantomWalletAddress: string[] = phantomConnectorUser!.get("solAccounts");
                    if (walletDuplicationVerification('sol', phantomWalletAddress[0])) {
                        return toastError(`${t('wallet-already-certified')}`);
                    }
                    await updateWalletInfo(phantomWalletAddress[0], "phantom")
                }
            } catch (e) {
                return toastError(`${t('something-wrong')}`);
            } finally {
                setWalletLoading(false)
                setFlag(false);
            }
        }


        /** 만약에 첫로그인이여서 새로만들어주는 userid 라면 지갑 로그인할때 api 호출해서 업데이트 해주는 함수 **/
        const setUpdateUser = async (objectId: string) => {
            try {
                const setUserPath = `/user?alias=${env}&requestType=setUserId&userId=${user.id}&objectId=${objectId}`
                await API.get(API_ARGOS_ID, setUserPath, {});
            } catch (e) {
                console.log("Wallet Login setUser Error", e)
            }
        }
        const deleteWallet = async (index: number) => {
            try {
                flushSync(() => setFlag(true))
                setWalletLoading(true);
                if (flag) {
                    return;
                }
                const toBeDeleted = wallet.find((_: IWallet, walletIndex) => index === walletIndex);
                if (!toBeDeleted) return;
                let params = {
                    body: {
                        address: toBeDeleted.address,
                        userId: user.id,
                        alias: env,
                        requestType: 'delete',
                        name: toBeDeleted.walletName,
                        chainId: toBeDeleted.chainId,
                        id: toBeDeleted.id
                    },
                };
                const restWallet: IWallet[] = wallet.filter((_: IWallet, walletIndex) => walletIndex !== index);
                const deleteWalletResponse = await API.post(API_ARGOS_ID, '/wallet', params)
                if (deleteWalletResponse.statusCode !== 200) {
                    setWalletLoading(false)
                    return toastError(deleteWalletResponse.message)
                }
                if (deleteWalletResponse.statusCode === 200) {
                    setWalletLoading(false)
                    toastSuccess(`${t('success-delete-wallet')}`)
                    return setWallet(restWallet)
                }
            } catch (e) {
                return toastError(`${t('failed-delete-wallet')}`);
            } finally {
                setFlag(false);
            }
        }


        if (loading) {
            return <RingsLoaderMemo/>
        }



        return (
            <section className={'flex flex-col mt-1.5'}>
                <h3 className={'font-bold text-sm flex items-center'}>
                    {t("confirmed-wallets")}
                    {walletLoading && <SmallLoading/>}
                </h3>
                {
                    wallet.length === 0 ?
                        <div className={'mt-5 leading-5 text-lg flex items-center justify-center flex-col'}
                             style={{'height': '170px'}}>
                            <FontAwesomeIcon icon={faWallet}
                                             className={'ml-1.5 text-4xl text-gray-700 cursor-pointer'}/>
                            <h4 className={'mt-3 text-center text-2xl text-gray-700'}>{t('no-wallets-verified')}</h4>
                        </div>
                        :
                        <WalletList
                            wallet={wallet}
                            deleteWallet={deleteWallet}
                        />
                }




                <VerifyPersonWallet
                    setNextPageAnimation={setNextPageAnimation}
                    setRouter={setRouter}
                    currentSelectNetwork={currentSelectNetwork}
                    setCurrentSelectNetwork={setCurrentSelectNetwork}
                />


                <h3 className={'font-bold text-sm mb-2'}>2. {t("supported-wallets")}</h3>
                <SupportedWalletsList walletLogin={walletLogin} phantomLogin={phantomLogin} currentSelectNetwork={currentSelectNetwork}/>
            </section>
        )
    }
export default Walletes