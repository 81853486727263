import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {IMainRouter, ISubRouter, IUser} from "../../types/type";
import EachSettingList from "../../components/EachSettingList";
import {IHistory, ISettingElement} from "../../types/type";
import AuthHistory from "./AuthHistory";
import SubRouterHeader from "../../components/common/SubRouterHeader";
import {SETTING_ELEMENT} from "../../constant";
import Language from "./Language";
import ServiceAgreement from "./ServiceAgreement";
import DeleteArgosId from "./DeleteArgosId";
import {useTranslation} from "react-i18next";
import i18n from "../../lang/i18n";
import {useTranslationText} from "../../hooks/useTranslationText";

interface ISetting {
    subRouter: ISubRouter
    changeSubRouter: (subRouter: ISubRouter) => void;
    history: IHistory[]
    user: IUser
    setRouter: Dispatch<SetStateAction<IMainRouter>>
    setSubmission: React.Dispatch<SetStateAction<any>>
    setUser: React.Dispatch<SetStateAction<IUser>>
    userEmail: string
    env : 'dev' | 'live'
}

const Setting: React.FC<ISetting> =
    ({
         user,
         setUser,
         subRouter,
         changeSubRouter,
         history,
         setRouter,
         setSubmission,
         userEmail,
        env
     }) => {

        const {t} = useTranslation();
        const [translateResult] = useTranslationText(SETTING_ELEMENT, [
            {
                title: t('services-setting'),
                items: [
                    t('auth-history'),
                    t('delete-argos-id'),
                ]
            },
            {
                title: t('customer-support'),
                items: [
                    t('announcement'),
                    t('cs'),
                ]
            },
            {
                title: t('services-policy'),
                items: [
                    t('terms-services-agreement'),
                    t('company-info'),
                ]
            }
        ])
        if (subRouter === 'deleteArgosID') {

            return (
                <>
                    <SubRouterHeader changeSubRouter={changeSubRouter} subTitle="delete"/>
                    <DeleteArgosId user={user} setUser={setUser} setRouter={setRouter} setSubmission={setSubmission}
                                   userEmail={userEmail} env={env}/>
                </>
            )
        }
        if (subRouter === 'authHistory') {

            return (
                <>
                    <SubRouterHeader
                        changeSubRouter={changeSubRouter}
                    />
                    <AuthHistory
                        argosId={user.argosId}
                        history={history}
                    />
                </>
            )
        }
        if (subRouter === 'language') {
            return (
                <>
                    <SubRouterHeader changeSubRouter={changeSubRouter}/>
                    <Language/>
                </>
            )
        }
        if (subRouter === 'serviceAgreement') {
            return (
                <>
                    <SubRouterHeader changeSubRouter={changeSubRouter}/>
                    <ServiceAgreement
                        user={user}
                        env={env}
                        setUser={setUser}
                        changeSubRouter={changeSubRouter}
                    />
                </>
            )
        }
        return (
            <ul className={'flex flex-col gap-y-2'}>
                {
                    translateResult.map((list) => {
                        return (
                            <EachSettingList
                                key={list.title}
                                setting={list}
                                changeSubRouter={changeSubRouter}
                            />
                        )
                    })
                }
            </ul>
        )
    }
export default Setting;