export const country = [

    {
        "label": "American Samoa",
        "name": "american-samoa",
        "value": "ASM",
        "infertext": "아메리칸사모아",
        "status": true
    },

    {
        "label": "Anguilla",
        "name": "anguilla",
        "value": "AIA",
        "infertext": "앵귈라",
        "status": true
    },
    {
        "label": "Antarctica",
        "name": "antarctica",
        "value": "ATA",
        "infertext": "남극",
        "status": true
    },
    {
        "label": "Bonaire, Sint Eustatius and Saba",
        "name": "bonaire",
        "value": "BES",
        "infertext": "카리브 네덜란드",
        "status": true
    },

    {
        "label": "Bouvet Island",
        "name": "bouvet-island",
        "value": "BVT",
        "infertext": "부베 섬",
        "status": true
    },

    {
        "label": "British Indian Ocean Territory (the)",
        "name": "british-indian",
        "value": "IOT",
        "infertext": "영국령 인도양 지역",
        "status": true
    },
    {
        "label": "Christmas Island",
        "name": "christmas-island",
        "value": "CXR",
        "infertext": "크리스마스 섬",
        "status": true
    },
    {
        "label": "Cocos (Keeling) Islands (the)",
        "name": "cocos-islands",
        "value": "CCK",
        "infertext": "코코스 제도",
        "status": true
    },
    {
        "label": "Curaçao",
        "name": "curacao",
        "value": "CUW",
        "infertext": "퀴라소",
        "status": true
    },
    {
        "label": "Falkland Islands (the) [Malvinas]",
        "name": "falkland-islands",
        "value": "FLK",
        "infertext": "포클랜드 제도",
        "status": true
    },
    {
        "label": "Faroe Islands (the)",
        "name": "faroe-islands",
        "value": "FRO",
        "infertext": "페로 제도",
        "status": true
    },
    {
        "label": "French Guiana",
        "name": "french-guiana",
        "value": "GUF",
        "infertext": "프랑스령 기아나",
        "status": true
    },
    {
        "label": "French Polynesia",
        "name": "french-polynesia",
        "value": "PYF",
        "infertext": "프랑스령 폴리네시아",
        "status": true
    },

    {
        "label": "French Southern Territories (the)",
        "name": "french-southern",
        "value": "ATF",
        "infertext": "프랑스령 남방 및 남극 지역",
        "status": true
    },

    {
        "label": "Gibraltar",
        "name": "gibraltar",
        "value": "GIB",
        "infertext": "지브롤터",
        "status": true
    },
    {
        "label": "Greenland",
        "name": "greenland",
        "value": "GRL",
        "infertext": "그린란드",
        "status": true
    },
    {
        "label": "Guadeloupe",
        "name": "guadeloupe",
        "value": "GLP",
        "infertext": "과들루프",
        "status": true
    },
    {
        "label": "Guernsey",
        "name": "guernsey",
        "value": "GGY",
        "infertext": "건지섬",
        "status": true
    },
    {
        "label": "Heard Island and McDonald Islands",
        "name": "heard-island",
        "value": "HMD",
        "infertext": "허드 맥도널드 제도",
        "status": true
    },

    {
        "label": "Holy See (the)",
        "name": "holy-see",
        "value": "VAT",
        "infertext": "바티칸 시국",
        "status": true
    },

    {
        "label": "Isle of Man",
        "name": "isle-of-man",
        "value": "IMN",
        "infertext": "맨섬",
        "status": true
    },
    {
        "label": "Jersey",
        "name": "jersey",
        "value": "JEY",
        "infertext": "저지섬",
        "status": true
    },
    {
        "label": "Kiribati",
        "name": "kiribati",
        "value": "KIR",
        "infertext": "키리바시",
        "status": true
    },
    {
        "label": "Macao",
        "name": "macao",
        "value": "MAC",
        "infertext": "마카오",
        "status": true
    },
    {
        "label": "Marshall Islands (the)",
        "name": "marshall-islands",
        "value": "MHL",
        "infertext": "마셜 제도",
        "status": true
    },
    {
        "label": "Martinique",
        "name": "martinique",
        "value": "MTQ",
        "infertext": "마르티니크",
        "status": true
    },
    {
        "label": "Mayotte",
        "name": "mayotte",
        "value": "MYT",
        "infertext": "마요트",
        "status": true
    },
    {
        "label": "Montenegro",
        "name": "montenegro",
        "value": "MNE",
        "infertext": "몬테네그로",
        "status": true
    },

    {
        "label": "Montserrat",
        "name": "montserrat",
        "value": "MSR",
        "infertext": "몬트세랫",
        "status": true
    },
    {
        "label": "New Caledonia",
        "name": "new-caledonia",
        "value": "NCL",
        "infertext": "누벨칼레도니",
        "status": true
    },
    {
        "label": "Niue",
        "name": "niue",
        "value": "NIU",
        "infertext": "니우에",
        "status": true
    },

    {
        "label": "Northern Mariana Islands (the)",
        "name": "northern-mariana-islands",
        "value": "MNP",
        "infertext": "북마리아나 제도",
        "status": true
    },

    {
        "label": "Pitcairn",
        "name": "pitcairn",
        "value": "PCN",
        "infertext": "핏케언 제도",
        "status": true
    },
    {
        "label": "Réunion",
        "name": "reunion",
        "value": "REU",
        "infertext": "레위니옹",
        "status": true
    },

    {
        "label": "Saint Barthélemy",
        "name": "saint-barthelemy",
        "value": "BLM",
        "infertext": "생바르텔레미",
        "status": true
    },
    {
        "label": "Saint Helena, Ascension and Tristan da Cunha",
        "name": "saint-helena",
        "value": "SHN",
        "infertext": "세인트헬레나",
        "status": true
    },

    {
        "label": "Saint Martin (French part)",
        "name": "saint-martin",
        "value": "MAF",
        "infertext": "생마르탱",
        "status": true
    },
    {
        "label": "Saint Pierre and Miquelon",
        "name": "saint-pierre-and-miquelon",
        "value": "SPM",
        "infertext": "생피에르 미클롱",
        "status": true
    },
    {
        "label": "Sint Maarten (Dutch part)",
        "name": "sint-maarten",
        "value": "SXM",
        "infertext": "신트마르턴",
        "status": true
    },
    {
        "label": "South Georgia and the South Sandwich Islands",
        "name": "south-georgia",
        "value": "SGS",
        "infertext": "사우스조지아 사우스샌드위치 제도",
        "status": true
    },
    {
        "label": "Svalbard and Jan Mayen",
        "name": "svalbard",
        "value": "SJM",
        "infertext": "스발바르 얀마옌",
        "status": true
    },
    {
        "label": "Timor-Leste",
        "name": "timor-leste",
        "value": "TLS",
        "infertext": "동티모르",
        "status": true
    },
    {
        "label": "Tokelau",
        "name": "tokelau",
        "value": "TKL",
        "infertext": "토켈라우",
        "status": true
    },
    {
        "label": "Tuvalu",
        "name": "tuvalu",
        "value": "TUV",
        "infertext": "투발루",
        "status": true
    },
    {
        "label": "United States Minor Outlying Islands (the)",
        "name": "united-states-minor-outlying-islands",
        "value": "UMI",
        "infertext": "미국령 군소 제도",
        "status": true
    },
    {
        "label": "Wallis and Futuna",
        "name": "wallis-and-futuna",
        "value": "WLF",
        "infertext": "왈리스 푸투나",
        "status": true
    },

    {
        "label": "South Sudan",
        "name": "south-sudan",
        "value": "SSD",
        "infertext": "남수단",
        "status": true
    },

    {
        "label": "Turks and Caicos Islands (the)",
        "name": "turks-and-caicos-islands",
        "value": "TCA",
        "infertext": "터크스 케이커스 제도",
        "status": true
    },
    {
        "label": "Western Sahara",
        "name": "western-sahara",
        "value": "ESH",
        "infertext": "서사하라",
        "status": true
    },
    {
        "label": "Åland Islands",
        "name": "aland-islands",
        "value": "ALA",
        "infertext": "올란드 제도",
        "status": true
    },


    {
        "label": "Afghanistan",
        "name": "afghanistan",
        "value": "AFG",
        "infertext": "아프가니스탄",
        "status": true
    },

    {
        "label": "Albania",
        "name": "albania",
        "value": "ALB",
        "infertext": "알바니아",
        "status": true
    },
    {
        "label": "Algeria",
        "name": "algeria",
        "value": "DZA",
        "infertext": "알제리",
        "status": true
    },
    {
        "label": "Andorra",
        "name": "andorra",
        "value": "AND",
        "infertext": "안도라",
        "status": true
    },
    {
        "label": "Angola",
        "name": "angola",
        "value": "AGO",
        "infertext": "앙골라",
        "status": true
    },
    {
        "label": "Antiguaand barbuda",
        "name": "antigua-and-barbuda",
        "value": "ATG",
        "infertext": "앤티과바부다",
        "status": true
    },
    {
        "label": "Argentina",
        "name": "argentina",
        "value": "ARG",
        "infertext": "아르헨티나",
        "status": true
    },
    {
        "label": "Armenia",
        "name": "armenia",
        "value": "ARM",
        "infertext": "아르메니아",
        "status": true
    },
    {
        "label": "Aruba",
        "name": "aruba",
        "value": "ABW",
        "infertext": "아루바",
        "status": true
    },
    {
        "label": "Samoa",
        "name": "samoa",
        "value": "WSM",
        "infertext": "사모아",
        "status": true
    },
    {
        "label": "Australia",
        "name": "australia",
        "value": "AUS",
        "infertext": "오스트레일리아",
        "status": true
    },
    {
        "label": "Austria",
        "name": "austria",
        "value": "AUT",
        "infertext": "오스트리아",
        "status": true
    },
    {
        "label": "Azerbaijan",
        "name": "azerbaijan",
        "value": "AZE",
        "infertext": "아제르바이잔",
        "status": true
    },
    {
        "label": "Bahamas",
        "name": "bahamas",
        "value": "BHS",
        "infertext": "바하마",
        "status": true
    },
    {
        "label": "Bangladesh",
        "name": "bangladesh",
        "value": "BGD",
        "infertext": "방글라데시",
        "status": true
    },
    {
        "label": "Barbados",
        "name": "barbados",
        "value": "BRB",
        "infertext": "바베이도즈",
        "status": true
    },
    {
        "label": "Burundi",
        "name": "burundi",
        "value": "BDI",
        "infertext": "부룬디",
        "status": true
    },
    {
        "label": "Belgium",
        "name": "belgium",
        "value": "BEL",
        "infertext": "벨기에",
        "status": true
    },
    {
        "label": "Benin",
        "name": "benin",
        "value": "BEN",
        "infertext": "베넹",
        "status": true
    },
    {
        "label": "Bermuda",
        "name": "bermuda",
        "value": "BMU",
        "infertext": "버뮤다",
        "status": true
    },
    {
        "label": "Bhutan",
        "name": "bhutan",
        "value": "BTN",
        "infertext": "부탄",
        "status": true
    },
    {
        "label": "Bosnia and herzegovina",
        "name": "bosnia-and-herzegovina",
        "value": "BIH",
        "infertext": "보스니아헤르체고비나",
        "status": true
    },
    {
        "label": "Belize",
        "name": "belize",
        "value": "BLZ",
        "infertext": "벨리즈",
        "status": true
    },
    {
        "label": "Belarus",
        "name": "belarus",
        "value": "BLR",
        "infertext": "벨라루스",
        "status": true
    },
    {
        "label": "Bolivia",
        "name": "bolivia",
        "value": "BOL",
        "infertext": "볼리비아",
        "status": true
    },
    {
        "label": "Botswana",
        "name": "botswana",
        "value": "BWA",
        "infertext": "보츠와나",
        "status": true
    },
    {
        "label": "Brazil",
        "name": "brazil",
        "value": "BRA",
        "infertext": "브라질",
        "status": true
    },
    {
        "label": "Bahrain",
        "name": "bahrain",
        "value": "BHR",
        "infertext": "바레인",
        "status": true
    },
    {
        "label": "Brunei",
        "name": "brunei",
        "value": "BRN",
        "infertext": "브루나이",
        "status": true
    },
    {
        "label": "Bulgaria",
        "name": "bulgaria",
        "value": "BGR",
        "infertext": "불가리아",
        "status": true
    },
    {
        "label": "Burkina Faso",
        "name": "burkina-faso",
        "value": "BFA",
        "infertext": "부르키나파소",
        "status": true
    },
    {
        "label": "Central African Republic (the)",
        "name": "central-african-republic",
        "value": "CAF",
        "infertext": "중앙아프리카공화국",
        "status": true
    },
    {
        "label": "Cambodia",
        "name": "cambodia",
        "value": "KHM",
        "infertext": "캄보디아",
        "status": true
    },
    {
        "label": "Canada",
        "name": "canada",
        "value": "CAN",
        "infertext": "캐나다",
        "status": true
    },
    {
        "label": "Cayman",
        "name": "cayman-islands",
        "value": "CYM",
        "infertext": "케이먼 제도",
        "status": true
    },
    {
        "label": "Congo (the)",
        "name": "republic-of-the-congo",
        "value": "COG",
        "infertext": "콩고",
        "status": true
    },
    {
        "label": "Chad",
        "name": "chad",
        "value": "TCD",
        "infertext": "차드",
        "status": true
    },
    {
        "label": "Chile",
        "name": "chile",
        "value": "CHL",
        "infertext": "칠레",
        "status": true
    },
    {
        "label": "China",
        "name": "china",
        "value": "CHN",
        "infertext": "중국 中文 中国 中华人民共和国",
        "status": true
    },
    {
        "label": "Côte d'Ivoire",
        "name": "coted_d_ivoire",
        "value": "CIV",
        "infertext": "코트디부아르",
        "status": true
    },
    {
        "label": "Cameroon",
        "name": "cameroon",
        "value": "CMR",
        "infertext": "카메룬",
        "status": true
    },
    {
        "label": "Congo (the Democratic Republic of the)",
        "name": "democratic-republic-of-congo",
        "value": "COD",
        "infertext": "콩고민주공화국",
        "status": true
    },
    {
        "label": "Cook Islands (the)",
        "name": "cook-islands",
        "value": "COK",
        "infertext": "쿡",
        "status": true
    },
    {
        "label": "Colombia",
        "name": "colombia",
        "value": "COL",
        "infertext": "콜롬비아",
        "status": true
    },
    {
        "label": "Comoros (the)",
        "name": "comoros",
        "value": "COM",
        "infertext": "코모로",
        "status": true
    },
    {
        "label": "Cabo Verde",
        "name": "cape-verde",
        "value": "CPV",
        "infertext": "케이프베르데",
        "status": true
    },
    {
        "label": "Costa Rica",
        "name": "costa-rica",
        "value": "CRI",
        "infertext": "코스타리카",
        "status": true
    },
    {
        "label": "Croatia",
        "name": "croatia",
        "value": "HRV",
        "infertext": "크로아티아",
        "status": true
    },
    {
        "label": "Cuba",
        "name": "cuba",
        "value": "CUB",
        "infertext": "쿠바",
        "status": true
    },
    {
        "label": "Cyprus",
        "name": "cyprus",
        "value": "CYP",
        "infertext": "키프러스",
        "status": true
    },
    {
        "label": "Czechia",
        "name": "czech-republic",
        "value": "CZE",
        "infertext": "체코",
        "status": true
    },
    {
        "label": "Denmark",
        "name": "denmark",
        "value": "DNK",
        "infertext": "덴마크",
        "status": true
    },
    {
        "label": "Djibouti",
        "name": "djibouti",
        "value": "DJI",
        "infertext": "지부티",
        "status": true
    },
    {
        "label": "Dominica",
        "name": "dominica",
        "value": "DMA",
        "infertext": "도미니카 연방",
        "status": true
    },
    {
        "label": "Dominican Republic (the)",
        "name": "dominican-republic",
        "value": "DOM",
        "infertext": "도미니카 공화국",
        "status": true
    },
    {
        "label": "Ecuador",
        "name": "ecuador",
        "value": "ECU",
        "infertext": "에콰도르",
        "status": true
    },
    {
        "label": "Egypt",
        "name": "egypt",
        "value": "EGY",
        "infertext": "이집트",
        "status": true
    },
    {
        "label": "Eritrea",
        "name": "eritrea",
        "value": "ERI",
        "infertext": "에리트레아",
        "status": true
    },
    {
        "label": "El Salvador",
        "name": "el-salvador",
        "value": "SLV",
        "infertext": "엘살바도르",
        "status": true
    },
    {
        "label": "Spain",
        "name": "spain",
        "value": "ESP",
        "infertext": "스페인 Español español",
        "status": true
    },
    {
        "label": "Estonia",
        "name": "estonia",
        "value": "EST",
        "infertext": "에스토니아",
        "status": true
    },
    {
        "label": "Ethiopia",
        "name": "ethiopia",
        "value": "ETH",
        "infertext": "에티오피아",
        "status": true
    },
    {
        "label": "Fiji",
        "name": "fiji",
        "value": "FJI",
        "infertext": "피지",
        "status": true
    },
    {
        "label": "Finland",
        "name": "finland",
        "value": "FIN",
        "infertext": "핀란드",
        "status": true
    },
    {
        "label": "France",
        "name": "france",
        "value": "FRA",
        "infertext": "프랑스",
        "status": true
    },
    {
        "label": "Micronesia",
        "name": "micronesia",
        "value": "FSM",
        "infertext": "미크로네시아",
        "status": true
    },
    {
        "label": "Gabon",
        "name": "gabon",
        "value": "GAB",
        "infertext": "가봉",
        "status": true
    },
    {
        "label": "Gambia",
        "name": "gambia",
        "value": "GMB",
        "infertext": "감비아",
        "status": true
    },
    {
        "label": "United Kingdom of Great Britain and Northern Ireland (the)",
        "name": "united-kingdom",
        "value": "GBR",
        "infertext": "영국",
        "status": true
    },
    {
        "label": "Guinea Bissau",
        "name": "guinea-bissau",
        "value": "GNB",
        "infertext": "기니비사우",
        "status": true
    },
    {
        "label": "Georgia",
        "name": "georgia",
        "value": "GEO",
        "infertext": "조지아",
        "status": true
    },
    {
        "label": "Equatorial Guinea",
        "name": "equatorial-guinea",
        "value": "GNQ",
        "infertext": "적도 기니",
        "status": true
    },
    {
        "label": "Germany",
        "name": "germany",
        "value": "DEU",
        "infertext": "독일",
        "status": true
    },
    {
        "label": "Ghana",
        "name": "ghana",
        "value": "GHA",
        "infertext": "가나",
        "status": true
    },
    {
        "label": "Greece",
        "name": "greece",
        "value": "GRC",
        "infertext": "그리스",
        "status": true
    },
    {
        "label": "Grenada",
        "name": "grenada",
        "value": "GRD",
        "infertext": "그레나다",
        "status": true
    },
    {
        "label": "Guatemala",
        "name": "guatemala",
        "value": "GTM",
        "infertext": "과테말라",
        "status": true
    },
    {
        "label": "Guinea",
        "name": "guinea",
        "value": "GIN",
        "infertext": "기니",
        "status": true
    },
    {
        "label": "Guam",
        "name": "guam",
        "value": "GUM",
        "infertext": "괌",
        "status": true
    },
    {
        "label": "Guyana",
        "name": "guyana",
        "value": "GUY",
        "infertext": "가이아나",
        "status": true
    },
    {
        "label": "Haiti",
        "name": "haiti",
        "value": "HTI",
        "infertext": "아이티",
        "status": true
    },
    {
        "label": "Hong Kong",
        "name": "hong-kong",
        "value": "HKG",
        "infertext": "홍콩",
        "status": true
    },
    {
        "label": "Honduras",
        "name": "honduras",
        "value": "HND",
        "infertext": "온두라스",
        "status": true
    },
    {
        "label": "Hungary",
        "name": "hungary",
        "value": "HUN",
        "infertext": "헝가리",
        "status": true
    },
    {
        "label": "Indonesia",
        "name": "indonesia",
        "value": "IDN",
        "infertext": "인도네시아",
        "status": true
    },
    {
        "label": "India",
        "name": "india",
        "value": "IND",
        "infertext": "인도",
        "status": true
    },
    {
        "label": "Iran",
        "name": "iran",
        "value": "IRN",
        "infertext": "이란",
        "status": false
    },
    {
        "label": "Ireland",
        "name": "ireland",
        "value": "IRL",
        "infertext": "아일랜드",
        "status": true
    },
    {
        "label": "Iraq",
        "name": "iraq",
        "value": "IRQ",
        "infertext": "이라크",
        "status": true
    },
    {
        "label": "Iceland",
        "name": "iceland",
        "value": "ISL",
        "infertext": "아이슬란드",
        "status": true
    },
    {
        "label": "Israel",
        "name": "israel",
        "value": "ISR",
        "infertext": "이스라엘",
        "status": true
    },
    {
        "label": "Italy",
        "name": "italy",
        "value": "ITA",
        "infertext": "이탈리아",
        "status": true
    },
    {
        "label": "Virgin Islands (British)",
        "name": "british-virgin-islands",
        "value": "VGB",
        "infertext": "영국령 버진",
        "status": true
    },
    {
        "label": "Jamaica",
        "name": "jamaica",
        "value": "JAM",
        "infertext": "자메이카",
        "status": true
    },
    {
        "label": "Jordan",
        "name": "jordan",
        "value": "JOR",
        "infertext": "요르단",
        "status": true
    },
    {
        "label": "Japan",
        "name": "japan",
        "value": "JPN",
        "infertext": "일본 日本",
        "status": true
    },
    {
        "label": "Kazakhstan",
        "name": "kazakhstan",
        "value": "KAZ",
        "infertext": "카자흐스탄",
        "status": true
    },
    {
        "label": "Kenya",
        "name": "kenya",
        "value": "KEN",
        "infertext": "케냐",
        "status": true
    },
    {
        "label": "Kyrgyzstan",
        "name": "kyrgyzstan",
        "value": "KGZ",
        "infertext": "키르기즈스탄",
        "status": true
    },
    {
        "label": "South Korea",
        "name": "south-korea",
        "value": "KOR",
        "infertext": "대한민국 한국",
        "status": true
    },
    {
        "label": "Saudi arabia",
        "name": "saudi-arabia",
        "value": "SAU",
        "infertext": "사우디아라비아",
        "status": true
    },
    {
        "label": "Kuwait",
        "name": "kuwait",
        "value": "KWT",
        "infertext": "쿠웨이트",
        "status": true
    },
    {
        "label": "Laos",
        "name": "laos",
        "value": "LAO",
        "infertext": "라오스",
        "status": true
    },
    {
        "label": "Latvia",
        "name": "latvia",
        "value": "LVA",
        "infertext": "라트비아",
        "status": true
    },
    {
        "label": "Libya",
        "name": "libya",
        "value": "LBY",
        "infertext": "리비아",
        "status": true
    },
    {
        "label": "Liberia",
        "name": "liberia",
        "value": "LBR",
        "infertext": "라이베리아",
        "status": true
    },
    {
        "label": "Saint Lucia",
        "name": "st-lucia",
        "value": "LCA",
        "infertext": "세인트루시아",
        "status": true
    },
    {
        "label": "Lesotho",
        "name": "lesotho",
        "value": "LSO",
        "infertext": "레소토",
        "status": true
    },
    {
        "label": "Lebanon",
        "name": "lebanon",
        "value": "LBN",
        "infertext": "레바논",
        "status": true
    },
    {
        "label": "Liechtenstein",
        "name": "liechtenstein",
        "value": "LIE",
        "infertext": "리히텐슈타인",
        "status": true
    },
    {
        "label": "Lithuania",
        "name": "lithuania",
        "value": "LTU",
        "infertext": "리투아니아",
        "status": true
    },
    {
        "label": "Luxembourg",
        "name": "luxembourg",
        "value": "LUX",
        "infertext": "룩셈부르크",
        "status": true
    },
    {
        "label": "Madagascar",
        "name": "madagascar",
        "value": "MDG",
        "infertext": "마다가스카르",
        "status": true
    },
    {
        "label": "Morocco",
        "name": "morocco",
        "value": "MAR",
        "infertext": "모로코",
        "status": true
    },
    {
        "label": "Malaysia",
        "name": "malaysia",
        "value": "MYS",
        "infertext": "말레이시아",
        "status": true
    },
    {
        "label": "Malawi",
        "name": "malawi",
        "value": "MWI",
        "infertext": "말라위",
        "status": true
    },
    {
        "label": "Moldova",
        "name": "moldova",
        "value": "MDA",
        "infertext": "몰도바",
        "status": true
    },
    {
        "label": "Maldives",
        "name": "maldives",
        "value": "MDV",
        "infertext": "몰디브",
        "status": true
    },
    {
        "label": "Mexico",
        "name": "mexico",
        "value": "MEX",
        "infertext": "멕시코",
        "status": true
    },
    {
        "label": "Mongolia",
        "name": "mongolia",
        "value": "MNG",
        "infertext": "몽골",
        "status": true
    },
    {
        "label": "Republic of North Macedonia",
        "name": "republic-of-macedonia",
        "value": "MKD",
        "infertext": "마케도니아",
        "status": true
    },
    {
        "label": "Mali",
        "name": "mali",
        "value": "MLI",
        "infertext": "말리",
        "status": true
    },
    {
        "label": "Malta",
        "name": "malta",
        "value": "MLT",
        "infertext": "몰타",
        "status": true
    },
    {
        "label": "Monaco",
        "name": "monaco",
        "value": "MCO",
        "infertext": "모나코",
        "status": true
    },
    {
        "label": "Mozambique",
        "name": "mozambique",
        "value": "MOZ",
        "infertext": "모잠비크",
        "status": true
    },
    {
        "label": "Mauritius",
        "name": "mauritius",
        "value": "MUS",
        "infertext": "모리셔스",
        "status": true
    },
    {
        "label": "Mauritania",
        "name": "mauritania",
        "value": "MRT",
        "infertext": "모리타니아",
        "status": true
    },
    {
        "label": "Myanmar",
        "name": "myanmar",
        "value": "MMR",
        "infertext": "미얀마",
        "status": true
    },
    {
        "label": "Namibia",
        "name": "namibia",
        "value": "NAM",
        "infertext": "나미비아",
        "status": true
    },
    {
        "label": "Nauru",
        "name": "nauru",
        "value": "NRU",
        "infertext": "나우루",
        "status": true
    },
    {
        "label": "Netherlands (the)",
        "name": "netherlands",
        "value": "NLD",
        "infertext": "네덜란드",
        "status": true
    },

    {
        "label": "Nepal",
        "name": "nepal",
        "value": "NPL",
        "infertext": "네팔",
        "status": true
    },
    {
        "label": "New Zealand",
        "name": "new-zealand",
        "value": "NZL",
        "infertext": "뉴질란드",
        "status": true
    },
    {
        "label": "Nicaragua",
        "name": "nicaragua",
        "value": "NIC",
        "infertext": "니카라과",
        "status": true
    },
    {
        "label": "Nigeria",
        "name": "nigeria",
        "value": "NGA",
        "infertext": "나이지리아",
        "status": true
    },
    {
        "label": "Niger",
        "name": "niger",
        "value": "NER",
        "infertext": "니제르",
        "status": true
    },
    {
        "label": "Norfolk",
        "name": "norfolk",
        "value": "NFK",
        "infertext": "노퍽",
        "status": true
    },
    {
        "label": "Norway",
        "name": "norway",
        "value": "NOR",
        "infertext": "노르웨이",
        "status": true
    },
    {
        "label": "Oman",
        "name": "oman",
        "value": "OMN",
        "infertext": "오만",
        "status": true
    },
    {
        "label": "Pakistan",
        "name": "pakistan",
        "value": "PAK",
        "infertext": "파키스탄",
        "status": true
    },
    {
        "label": "Panama",
        "name": "panama",
        "value": "PAN",
        "infertext": "파나마",
        "status": true
    },
    {
        "label": "Paraguay",
        "name": "paraguay",
        "value": "PRY",
        "infertext": "파라과이",
        "status": true
    },
    {
        "label": "Peru",
        "name": "peru",
        "value": "PER",
        "infertext": "페루",
        "status": true
    },
    {
        "label": "Philippines",
        "name": "philippines",
        "value": "PHL",
        "infertext": "필리핀 pilip",
        "status": true
    },
    {
        "label": "Palestine",
        "name": "palestine",
        "value": "PSE",
        "infertext": "팔레스타인",
        "status": true
    },
    {
        "label": "Palau",
        "name": "palau",
        "value": "PLW",
        "infertext": "팔라우",
        "status": true
    },
    {
        "label": "Papua New Guinea",
        "name": "papua-new-guinea",
        "value": "PNG",
        "infertext": "파푸아뉴기니",
        "status": true
    },
    {
        "label": "Poland",
        "name": "republic-of-poland",
        "value": "POL",
        "infertext": "폴란드",
        "status": true
    },
    {
        "label": "Portugal",
        "name": "portugal",
        "value": "PRT",
        "infertext": "포르투갈 portug português Português",
        "status": true
    },
    {
        "label": "North Korea",
        "name": "north-korea",
        "value": "PRK",
        "infertext": "북한",
        "status": false
    },
    {
        "label": "Puerto Rico",
        "name": "puerto-rico",
        "value": "PRI",
        "infertext": "푸에르토리코",
        "status": true
    },
    {
        "label": "Qatar",
        "name": "qatar",
        "value": "QAT",
        "infertext": "카타르",
        "status": true
    },
    {
        "label": "Romania",
        "name": "romania",
        "value": "ROU",
        "infertext": "루마니아",
        "status": true
    },
    {
        "label": "South Africa",
        "name": "south-africa",
        "value": "ZAF",
        "infertext": "남아프리카공화국",
        "status": true
    },
    {
        "label": "Russia",
        "name": "russia",
        "value": "RUS",
        "infertext": "러시아",
        "status": true
    },
    {
        "label": "Rwanda",
        "name": "rwanda",
        "value": "RWA",
        "infertext": "르완다",
        "status": true
    },
    {
        "label": "Serbia",
        "name": "serbia",
        "value": "SRB",
        "infertext": "세르비아",
        "status": true
    },
    {
        "label": "Senegal",
        "name": "senegal",
        "value": "SEN",
        "infertext": "세네갈",
        "status": true
    },
    {
        "label": "Seychelles",
        "name": "seychelles",
        "value": "SYC",
        "infertext": "세이셸",
        "status": true
    },
    {
        "label": "Singapore",
        "name": "singapore",
        "value": "SGP",
        "infertext": "싱가포르",
        "status": true
    },
    {
        "label": "Saint Kitts and Nevis",
        "name": "saint-kitts-and-nevis",
        "value": "KNA",
        "infertext": "세인트 키츠 앤 네비스",
        "status": true
    },
    {
        "label": "Sierra Leone",
        "name": "sierra-leone",
        "value": "SLE",
        "infertext": "시에라리온",
        "status": true
    },
    {
        "label": "Slovenia",
        "name": "slovenia",
        "value": "SVN",
        "infertext": "슬로베니아",
        "status": true
    },
    {
        "label": "San Marino",
        "name": "san-marino",
        "value": "SMR",
        "infertext": "산마리노",
        "status": true
    },
    {
        "label": "Solomon islands",
        "name": "solomon-islands",
        "value": "SLB",
        "infertext": "솔로몬",
        "status": true
    },
    {
        "label": "Somalia",
        "name": "somalia",
        "value": "SOM",
        "infertext": "소말리아",
        "status": true
    },
    {
        "label": "Sri Lanka",
        "name": "sri-lanka",
        "value": "LKA",
        "infertext": "스리랑카",
        "status": true
    },
    {
        "label": "Sao Tome And Principe",
        "name": "sao-tome-and-principe",
        "value": "STP",
        "infertext": "상투메 프린시페",
        "status": true
    },
    {
        "label": "Sudan",
        "name": "sudan",
        "value": "SDN",
        "infertext": "수단",
        "status": true
    },
    {
        "label": "Switzerland",
        "name": "switzerland",
        "value": "CHE",
        "infertext": "스위스",
        "status": true
    },
    {
        "label": "Suriname",
        "name": "suriname",
        "value": "SUR",
        "infertext": "수리남",
        "status": true
    },
    {
        "label": "Slovakia",
        "name": "slovakia",
        "value": "SVK",
        "infertext": "슬로바키아",
        "status": true
    },
    {
        "label": "Sweden",
        "name": "sweden",
        "value": "SWE",
        "infertext": "스웨덴",
        "status": true
    },
    {
        "label": "Swaziland",
        "name": "swaziland",
        "value": "SWZ",
        "infertext": "스와질랜드",
        "status": true
    },
    {
        "label": "Syria",
        "name": "syria",
        "value": "SYR",
        "infertext": "시리아",
        "status": true
    },
    {
        "label": "Tanzania",
        "name": "tanzania",
        "value": "TZA",
        "infertext": "탄자니아",
        "status": true
    },
    {
        "label": "Tonga",
        "name": "tonga",
        "value": "TON",
        "infertext": "통가",
        "status": true
    },
    {
        "label": "Thailand",
        "name": "thailand",
        "value": "THA",
        "infertext": "타이",
        "status": true
    },
    {
        "label": "Tajikistan",
        "name": "tadzhikistan",
        "value": "TJK",
        "infertext": "타지키스탄",
        "status": true
    },
    {
        "label": "Turkmenistan",
        "name": "turkmenistan",
        "value": "TKM",
        "infertext": "투르크메니스탄",
        "status": true
    },
    {
        "label": "Togo",
        "name": "togo",
        "value": "TGO",
        "infertext": "토고",
        "status": true
    },
    {
        "label": "Taiwan",
        "name": "taiwan",
        "value": "TWN",
        "infertext": "대만",
        "status": true
    },
    {
        "label": "Trinidad And Tobago",
        "name": "trinidad-and-tobago",
        "value": "TTO",
        "infertext": "트리니다드 토바고",
        "status": true
    },
    {
        "label": "Tunisia",
        "name": "tunisia",
        "value": "TUN",
        "infertext": "튀니지",
        "status": true
    },
    {
        "label": "Turkey",
        "name": "turkey",
        "value": "TUR",
        "infertext": "터키",
        "status": true
    },
    {
        "label": "United Arab Emirates (the)",
        "name": "united-arab-emirates",
        "value": "ARE",
        "infertext": "아랍 에미레이트",
        "status": true
    },
    {
        "label": "Uganda",
        "name": "uganda",
        "value": "UGA",
        "infertext": "우간다",
        "status": true
    },
    {
        "label": "Ukraine",
        "name": "ukraine",
        "value": "UKR",
        "infertext": "우크라이나",
        "status": true
    },
    {
        "label": "Uruguay",
        "name": "uruguay",
        "value": "URY",
        "infertext": "우루과이",
        "status": true
    },
    {
        "label": "United states",
        "name": "united-states",
        "value": "USA",
        "infertext": "미국 america America us US U.S USA usa",
        "status": true
    },
    {
        "label": "United States Virgin Islands",
        "name": "united-states-virgin-islands",
        "value": "VIR",
        "infertext": "버진",
        "status": true
    },
    {
        "label": "Uzbekistan",
        "name": "uzbekistn",
        "value": "UZB",
        "infertext": "우즈베키스탄 Uzbek",
        "status": true
    },
    {
        "label": "Vanuatu",
        "name": "vanuatu",
        "value": "VUT",
        "infertext": "바누아투",
        "status": true
    },
    {
        "label": "Venezuela",
        "name": "venezuela",
        "value": "VEN",
        "infertext": "베네수엘라",
        "status": true
    },
    {
        "label": "Vietnam",
        "name": "vietnam",
        "value": "VNM",
        "infertext": "베트남 Tiếng Việt việt viet",
        "status": true
    },
    {
        "label": "Saint Vincent and the Grenadines",
        "name": "st-vincent-and-the-grenadines",
        "value": "VCT",
        "infertext": "세인트 빈센트 그레나딘",
        "status": true
    },
    {
        "label": "Yemen",
        "name": "yemen",
        "value": "YEM",
        "infertext": "예멘",
        "status": true
    },

    {
        "label": "Zambia",
        "name": "zambia",
        "value": "ZMB",
        "infertext": "잠비아",
        "status": true
    },
    {
        "label": "Zimbabwe",
        "name": "zimbabwe",
        "value": "ZWE",
        "infertext": "짐바브웨",
        "status": true
    }
];

export default country;
