import React, {CSSProperties, memo} from 'react';


interface ICoinImage {
    bgColor : string;
    logoSrc : string;
    coinName : string;
    style ?: CSSProperties

}



const CoinImage : React.FC<ICoinImage> = ({bgColor , logoSrc, coinName , style = {}}) => {
    return (
        <div className={'coin-container' + ` ${bgColor}`} style={style}>
            <div className={'coin-sub-container'}>
                <img src={logoSrc} alt={`${coinName}-logo`} className={'w-9/12'}/>
            </div>
        </div>
    )
}

const MemoCoinImage =  memo(CoinImage);
export default MemoCoinImage;