import React, {useMemo} from 'react';

interface IButton {
    disabled: boolean
    onClick: (e ?: React.MouseEvent<HTMLButtonElement>) => void
    value: string
    theme ?: 'danger' | 'confirm'
}


const Button: React.FC<IButton> = ({disabled, onClick, value, theme = 'confirm'}) => {


    const btnColor : string = useMemo(() => {
        if (theme === 'confirm') {
            return "disabled:cursor-not-allowed disabled:opacity-50 disabled:hover mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        }
        return "disabled:cursor-not-allowed disabled:opacity-50 disabled:hover mt-5 text-white bg-rose-700 hover:bg-rose-800 focus:ring-4 focus:outline-none focus:ring-rose-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
    },[theme])


    return (
        <button type="button"
                disabled={disabled}
                onClick={onClick}
                className={btnColor}>
            {value}
        </button>
    )
}

export default React.memo(Button);