import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {SERVICE_AGREEMENT_OPTIONAL, SERVICE_AGREEMENT_REQUIRED} from "../../constant";
import {ISettingElement, ISubRouter, IUser} from "../../types/type";
import EachSettingList from "../../components/EachSettingList";
import {useTranslation} from "react-i18next";
import Toggle from "../../components/common/Toggle";
import SmallLoading from "../../components/common/SmallLoading";
import {API} from "aws-amplify";
import {toastError} from "../../toast";
import {useTranslationText} from "../../hooks/useTranslationText";


interface IServiceAgreement {
    user: IUser
    setUser: Dispatch<SetStateAction<IUser>>
    changeSubRouter: (subRouter: ISubRouter) => void;
    env : 'live' | 'dev'
}


const ServiceAgreement: React.FC<IServiceAgreement> =
    ({
         user,
         setUser,
         changeSubRouter,
        env
     }) => {
        const {t} = useTranslation();



        const [termsAndServiceAgreementRequired] = useTranslationText(SERVICE_AGREEMENT_REQUIRED, [
            {
                title: t('services-required'),
                items: [
                    t('terms-of-services'),
                    t('privacy-policy'),
                ]
            },
        ])
        const [termsAndServiceAgreementOptional] = useTranslationText(SERVICE_AGREEMENT_OPTIONAL, [
                {
                    title: t('services-optional'),
                    items: [
                        t('receive-advertisement'),
                    ]
                }
            ]);


        const [loading, setLoading] = useState<boolean>(false);

        // toggle handler
        const toggleMarketing = useCallback(async () => {
            try {
                setLoading(prev => !prev)
                setUser(prev => ({...prev , consentMarketing : !prev.consentMarketing}))
                await API.post('apiArgosID',`/user?alias=${env}&objectId=${user.objectId}&userId=${user.id}&requestType=setUserId`,{
                    body : {
                        consentMarketing: !user.consentMarketing
                    }
                });
            } catch (e) {
                return toastError('Somethings Wrong , Retry Again')
            } finally {
                setLoading(prev => !prev)
            }
        }, [])

        return (
            <>
                <ul className={'flex flex-col'}>
                    {
                        termsAndServiceAgreementRequired.map((list : ISettingElement) => {
                            return (
                                <EachSettingList key={list.title} setting={list} changeSubRouter={changeSubRouter}/>
                            )
                        })
                    }
                </ul>
                <ul className={'flex flex-col'}>
                    {
                        termsAndServiceAgreementOptional.map((list, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <EachSettingList key={list.title} setting={list} changeSubRouter={changeSubRouter}/>
                                    <div className={'flex flex-row-reverse mt-5 mb-5'}>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                    <article className={"-mt-5 w-full"}>
                        <span className={"flex grid justify-items-end"}>
                            <p className={"flex items-center"}>
                            {loading ?
                                <SmallLoading/> :
                                <Toggle checked={user.consentMarketing} onChange={toggleMarketing}/>}
                                {loading ? null : <span className={'text-xs text-gray-500 ml-2'}>{t('marketing-consent')}</span>}
                            </p>
                        </span>
                    </article>
                </ul>
            </>
        )
    }
export default ServiceAgreement;