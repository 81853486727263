import React , {memo} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";


interface IFace {
    faceImage : string
}


const Face : React.FC<IFace> =
    ({
        faceImage
     }) => {

    const {t} = useTranslation()

    return (
        <div className={'w-full flex flex-col gap-y-3'}>
            <div className={'flex w-full items-center gap-2.5'}>
                <FontAwesomeIcon icon={faImage}/>
                <h4>{t("face-photo")}</h4>
            </div>
            <img src={faceImage} alt={`faceImage`}/>
        </div>
    )
}
export default memo(Face)