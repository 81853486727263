import React, {useEffect, useState} from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import {IWallet} from "../types/type";
import EachCarousel from "./EachCarousel";
import { Swiper, SwiperSlide , useSwiper } from "swiper/react";
import {Swiper as SwiperClass} from "swiper/types";



interface ICarousel {
    wallet : IWallet[]
    deleteWallet : (index : number) => void
}


const WalletList : React.FC<ICarousel> = ({wallet , deleteWallet}) => {


    const [swiper, setSwiper] = useState<SwiperClass>();


    useEffect(() => {
        if (swiper && wallet && wallet.length > 0) swiper.slideTo(wallet.length);
    }, [wallet.length])


    return (
        <>
            <Swiper
                onSwiper={swiper => setSwiper(swiper)}
                slidesPerView={"auto"}
                centeredSlides={true}
                spaceBetween={10}
                className="mySwiper"
                style={{'margin' : '10px 0 5px 0'}}
            >
                {
                    wallet.map((wallet : IWallet , index) =>
                        <SwiperSlide key={wallet.chainId + wallet.address}
                                     style={{'width': '90%'}}
                        >
                            <EachCarousel wallet={wallet} deleteWallet={deleteWallet} seq={index}/>
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </>

    );
}
export default WalletList
