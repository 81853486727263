import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {useMoralis} from "react-moralis";
import 'react-toastify/dist/ReactToastify.css';
import uniqid from 'uniqid';
import MoralisType from "moralis";
import {API} from "aws-amplify";
import {IApiArgosId, IUser} from "../types/type";
import argos from '../assets/argos.svg';
import {API_ARGOS_ID} from "../constant";
import {useTranslation} from "react-i18next";
import SmallLoading from "../components/common/SmallLoading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEarthAmericas} from "@fortawesome/free-solid-svg-icons";


interface ISignIn {
    setUser: Dispatch<SetStateAction<IUser>>
    setAlreadyUserId: Dispatch<SetStateAction<boolean>>
    setFirstVisit: Dispatch<SetStateAction<boolean>>
    env :string
}


const SignIn: React.FC<ISignIn> = ({setUser , setAlreadyUserId, setFirstVisit , env}) => {

    const {
        authenticate,
        isWeb3EnableLoading
    } = useMoralis();
    const {t} = useTranslation();



    const login = async () => {
        try {


            if (isWeb3EnableLoading) return;

            const clientId : string = env === 'live' ? 'BG3TOhyvgeJ3DnTG0f813W9wJypVvd_mDrnwVHfYMMh4zuuE6u2Ecv7nx1fvuR5ENolrFW8cRsrox5xA4w6mQ2A' : "BG3TOhyvgeJ3DnTG0f813W9wJypVvd_mDrnwVHfYMMh4zuuE6u2Ecv7nx1fvuR5ENolrFW8cRsrox5xA4w6mQ2A"


            const user: MoralisType.User | undefined = await authenticate({
                throwOnError : true,
                signingMessage: "ARGOS ID Authentication",
                provider: "web3Auth",
                clientId : clientId,
                theme: 'light',
                loginMethodsOrder: ["google", "twitter", "linkedin", 'kakao', 'line'],
                appLogo : argos,
                rpcTarget : "https://cloudflare-eth.com",
                onComplete : () => document.getElementById('w3a-container')?.remove()
            })

            if (user) {
                const {id: objId} = user


                const apiPath = `/user?alias=${env}&requestType=getObject&objectId=${objId}`
                const userData: IApiArgosId = await API.get(API_ARGOS_ID, apiPath, {});

                if (userData.userId) {
                    setAlreadyUserId(true);
                    setUser(prev => ({
                        ...prev,
                        objectId: objId,
                        id: userData.userId as string,
                        consentMarketing : userData.consentMarketing
                    }))
                } else {
                    const userId = uniqid();
                    const setUserPath = `/user?alias=${env}&requestType=setUserId&userId=${userId}&objectId=${objId}`
                    await API.get(API_ARGOS_ID, setUserPath, {});
                    setUser(prev => ({
                        ...prev,
                        objectId: objId,
                        id: userId,
                        consentMarketing : userData.consentMarketing ?? false
                    }))
                    setFirstVisit(true);
                }
            }
        } catch (e) {
            console.log('Login Error',e)
        }
    }



    return (
        <div>
            <button type="button"
                    onClick={login}
                    className="argos-central-button">
                <img src={argos} alt={'argos-id'} style={{'width': '15px', 'height': '15px' , 'marginRight':'7px'}}/>
                {t("connect-user")}
                <FontAwesomeIcon icon={faEarthAmericas} style={{'marginLeft':'8px'}}/>
                {isWeb3EnableLoading && <SmallLoading borderRightColor={'deepskyblue'} />}
            </button>
        </div>
    );

}

export default SignIn