import React from 'react';
import {ISubRouter} from "../../types/type";
import {faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

const SubRouterHeader: React.FC<ISubRouterHeader> = (
    {
        changeSubRouter,
        subTitle
    }) => {
    const {t} = useTranslation()
    return (
        <div className="w-full justify-items-stretch mb-4 flex items-center">
            <FontAwesomeIcon icon={faArrowCircleLeft} className={'cursor-pointer justify-self-start'} onClick={() => changeSubRouter('')}/>
        </div>
    )
}

interface ISubRouterHeader {
    changeSubRouter: (subRouter: ISubRouter) => void;
    subTitle?: string
}

export default SubRouterHeader