import React, {Dispatch, SetStateAction} from 'react';



interface IToggle {
    // label?: string
    checked: boolean
    onChange: Dispatch<SetStateAction<boolean>>
}

const Toggle: React.FC<IToggle> = ({ checked,onChange}) => {

    return (
        <label htmlFor="checked-toggle" className="inline-flex relative items-center cursor-pointer ml-2">
            <input type="checkbox" id="checked-toggle" className="sr-only peer"
                   checked={checked}
                   onChange={() => onChange(prev => !prev)}/>
                <div
                    className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

        </label>
    )
}



export default Toggle;