import React, {Dispatch, memo, SetStateAction} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faGear, faWallet, faUser} from '@fortawesome/free-solid-svg-icons'
import {useMoralis} from "react-moralis";
import {IIdStatus, IUser} from "../../types/type";
import {toastError} from "../../toast";
import {IMainRouter, ISubRouter} from "../../types/type";
import {useTranslation} from "react-i18next";


interface IHeader {
    setUser: Dispatch<SetStateAction<IUser>>
    setRouter: React.Dispatch<React.SetStateAction<IMainRouter>>
    liveFormResult: IIdStatus | null
    router: IMainRouter
    setSubRouter: React.Dispatch<React.SetStateAction<ISubRouter>>
}

const Header: React.FC<IHeader> =
    ({
         setUser,
         liveFormResult,
         setRouter,
         router,
         setSubRouter
     }) => {

        const {t} = useTranslation()
        const {logout: moralisLogOut} = useMoralis()

        const logout = async () => {
            await moralisLogOut().then(() => {
                setUser(prev => ({
                    ...prev,
                    objectId: "",
                    id: "",
                    className: "",
                    uuid: "",
                    consentMarketing : false
                }))
            })
        }

        const changeRoute = (routerName: IMainRouter) => {
            if (liveFormResult !== IIdStatus.approved) {
                return toastError(`${t('kyc-status-must-be-approved')}`)
            }
            setRouter((prev) => {
                if (prev === routerName) {
                    setSubRouter(prevSubRouter => prevSubRouter);
                } else {
                    setSubRouter('')
                }
                return routerName
            });
        }


        return (
            <section
                className={'flex justify-between px-3 bg-blue-500 pb-1 pt-2 w-full relative items-center text-white'}>
                <div className={'flex items-center'}>

                    <div
                        className={`flex items-center justify-center flex-col cursor-pointer ${router === 'user' && 'text-black'}`}
                        onClick={() => changeRoute('user')}>
                        <FontAwesomeIcon icon={faUser} className={`text-sm cursor-pointer mb-1`}/>
                        <span className={'text-xxs '}>{t("user")}</span>
                    </div>
                    <div
                        className={`flex items-center justify-center flex-col cursor-pointer ml-4 ${(router === 'wallet' || router === 'changeNetwork') && 'text-black'}`}
                        onClick={() => changeRoute('wallet')}>
                        <FontAwesomeIcon icon={faWallet} className={'text-sm cursor-pointer mb-1'}/>
                        <span className={'text-xxs'}>{t("wallet")}</span>
                    </div>
                    <div
                        className={`flex items-center justify-center flex-col cursor-pointer ml-4 ${router === 'setting' && 'text-black'}`}
                        onClick={() => changeRoute('setting')}>
                        <FontAwesomeIcon icon={faGear} className={'text-sm cursor-pointer mb-1'}/>
                        <span className={'text-xxs'}>{t("header-setting")}</span>
                    </div>
                </div>
                <div className={'flex'}>
                    <FontAwesomeIcon onClick={logout} icon={faCircleXmark} className={'mx-2text-white cursor-pointer'}/>
                </div>


            </section>
        )
    }

export default memo(Header)