import React, {ImgHTMLAttributes, useEffect, useMemo, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRotateLeft, faCircleExclamation, faCircleUser, faIdCard} from "@fortawesome/free-solid-svg-icons";
import AliceCarousel from 'react-alice-carousel';
import {useTranslation} from "react-i18next";
import getCountryName from "../../lib/getCountryName";
import {getIdentityType} from "../../lib/getIdentityType";


interface IId {
    submission: any
}


const Id: React.FC<IId> =
    ({
         submission
     }) => {
        const {t} = useTranslation()
        return (
            <div className={'flex flex-col w-full h-full text-base'} >
                <div className={'flex w-full items-center gap-4'}>
                    <FontAwesomeIcon icon={faIdCard}/>
                    <h4>{t("iD-document")}</h4>
                </div>

                <div className={'flex flex-col w-full text-sm gap-y-6 mt-4'}>
                    <article className={'w-full flex gap-3 justify-between'}>
                        <div className={'text-gray-400'}>
                            {/*{t("issuing-country")}<br/>*/}
                            <span className={'mt-5 text-black'}>{getCountryName(submission?.nationality)}</span>
                        </div>
                        <div className={'text-gray-400'}>
                            {/*{t("id-type")} <br/>*/}
                            <span className={'mt-5 text-black'}>{getIdentityType(submission?.idType)}</span>
                        </div>
                    </article>
                    {
                        submission?.idImage &&
                        <article className={'w-full flex gap-3'}>
                            <AliceCarousel
                                infinite={false}
                                disableButtonsControls
                                mouseTracking={submission?.idBackImage ? true : false}
                                items={
                                    submission?.idBackImage ?
                                        [<img src={submission?.idImage} alt={'idImage'}/>,
                                            <img src={submission?.idBackImage} alt={"id Back Image"}/>
                                        ]
                                        :
                                        [<img src={submission?.idImage} alt={'idImage'}/>]
                                }
                            />
                        </article>
                    }

                    <article className={'w-full flex gap-3'}>
                        {submission?.documentNumber &&
                            <div className={'text-gray-400 flex-1'}>
                                {t("number")}<br/>
                                <span className={'mt-5 text-black'}>{submission?.documentNumber}</span>
                            </div>
                        }

                        {submission?.expireDate &&
                            <div className={'text-gray-400 flex-1'}>
                                {t("date-of-expiry")}<br/>
                                <span className={'mt-5 text-black'}>{submission?.expireDate}</span>
                            </div>
                        }

                        {
                            submission?.issueDate &&
                            <div className={'text-gray-400 flex-1'}>
                                {t("date-of-issue")}<br/>
                                <span className={'mt-5 text-black'}>{submission?.issueDate}</span>
                            </div>
                        }
                    </article>
                </div>
            </div>
        )
    }
export default Id