import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {ICurrentSelectNetwork, IMainRouter} from "../types/type";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import {CHAIN_NETWORK_LIST} from "../constant";
import {useTranslation} from "react-i18next";



interface IVerifyPersonWallet {
    setNextPageAnimation: Dispatch<SetStateAction<boolean>>
    setRouter: Dispatch<IMainRouter>
    currentSelectNetwork: ICurrentSelectNetwork
    setCurrentSelectNetwork: Dispatch<SetStateAction<ICurrentSelectNetwork>>
}


const VerifyPersonWallet: React.FC<IVerifyPersonWallet> =
    ({
         setNextPageAnimation,
         setRouter,
         currentSelectNetwork,
         setCurrentSelectNetwork
     }) => {


        const goToChangeNetwork = useCallback(() => {
            setNextPageAnimation(true);
            setRouter('changeNetwork')
        }, []);


        useEffect(() => {
            if (currentSelectNetwork.chainId || currentSelectNetwork.symbol) return
            setCurrentSelectNetwork(CHAIN_NETWORK_LIST[0])
        },[])



        const {t} = useTranslation()

        return (
            <section className={'my-3 flex flex-col text-sm font-bold'}>
                <h4 className={'my-1.5'}>{t("verify-personal-wallet")}</h4>
                <h5 className={'text-sm'}>1. {t('choose-network')}</h5>
                <div className={'flex items-center mt-3'}>
                    <div>{
                        currentSelectNetwork.chainId ?
                            currentSelectNetwork.logo
                            :
                            t('failed-to-detect-network')
                    }
                    </div>
                    <button
                        className={'rounded-md font-bold border-solid border-gray-300 bg-sky-500 px-1.5 py-1 text-xs text-white ml-1.5'}
                        onClick={goToChangeNetwork}>
                        {t('change-network')}
                        <FontAwesomeIcon icon={faArrowCircleRight} className={'ml-1.5'}/>
                    </button>
                </div>

            </section>
        )
    }
export default VerifyPersonWallet