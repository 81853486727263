import {useState, useEffect, RefObject} from 'react';

function useScrollPosition(ref: RefObject<HTMLElement>) {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        if (ref.current) {
            const element = ref.current;
            setScrollPosition(element.scrollTop / (element.scrollHeight - element.clientHeight) * 100);

            const handleScroll = () => {
                setScrollPosition(element.scrollTop / (element.scrollHeight - element.clientHeight) * 100);
            };

            element.addEventListener('scroll', handleScroll);

            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, [ref]);

    return scrollPosition;
}

export default useScrollPosition;
