import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import Modal from 'react-modal';
import Header from "../components/common/Header";
import Walletes from "./Walletes/Walletes";
import {
    IActivityCoins,
    ICertificate, ICurrentSelectNetwork,
    IHistory,
    IIdStatus,
    IMainRouter,
    ISubRouter,
    IUser,
    IWallet
} from "../types/type";
import {flushSync} from "react-dom";
import {API} from "aws-amplify";
import {ToastContainer} from "react-toastify";
import User from "./user/User";
import {toastError} from "../toast";
import Setting from "./settings/Setting";
import {API_ARGOS_ID} from "../constant";
import ConsentForm from "./user/ConsentForm";
import {useTranslation} from "react-i18next";
import uniqid from "uniqid";
import ModalWithChildren from "../components/common/ModalWithChildren";
import ChangeNetwork from "../components/ChangeNetwork";


const customStyles = {
    content: {
        top: '50%',
        width: '100%',
        height: '100%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "rgba(0,0,0,0.6)"
    },
};

interface IArgosId {
    user: IUser
    setUser: Dispatch<SetStateAction<IUser>>
    alreadyUserId: boolean
    firstVisit: boolean
    setFirstVisit: Dispatch<SetStateAction<boolean>>
    env : 'dev' | 'live'
}


const ArgosId: React.FC<IArgosId> =
    ({
         user,
         setUser,
         alreadyUserId,
         firstVisit,
         setFirstVisit,
        env
     }) => {


        const [router, setRouter] = useState<IMainRouter>('user');

        const [step, setStep] = useState<number>(0);
        const [nextPageAnimation, setNextPageAnimation] = useState<boolean>(false);
        const [prevPageAnimation, setPrevPageAnimation] = useState<boolean>(false);
        const [liveFormResult, setLiveFormResult] = useState<IIdStatus | null>(null);
        const [wallet, setWallet] = useState<IWallet[]>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const [submission, setSubmission] = useState<any>(null);
        const [subRouter, setSubRouter] = useState<ISubRouter>('');
        const [history, setHistory] = useState<IHistory[]>([]);
        const [currentSelectNetwork, setCurrentSelectNetwork] = useState<ICurrentSelectNetwork>({
            chainId: "",
            name: "",
            logo: null,
            networkUrl: "",
            symbol: "",
            blockExplorerUrls : "",
            nativeCurrency : {
                name : "",
                symbol : "",
                decimals : 18
            },
            shortName : ""
        });
        const [userEmail, setUserEmail] = useState<string>('');

        const {t} = useTranslation()

        useEffect(() => {
            (async () => {
                try {
                    if (firstVisit) {
                        return setRouter('firstVisit')
                    }
                    setLoading(true)

                    const res: any = await fetchingCertificateAndProject();


                    if (!res) {
                        return toastError(`${t('something-wrong')}`)
                    }


                    if (res) {
                        const {message, statusCode, idStatus, argosId, uuid, Item}: ICertificate = res[0]

                        if (!message && statusCode === 200) {
                            setLiveFormResult(idStatus)
                            setSubmission(Item)
                            setUser((prev) => {
                                if (argosId && uuid) {
                                    return ({...prev, argosId, uuid})
                                }
                                return prev
                            })
                        }


                        if (res[2] && res[2].statusCode === 200) {
                            const resWalletData: IWallet[] = res[2].Items.map((wallet: any) => {
                                return {
                                    address: wallet.address,
                                    chainId: wallet.chainId,
                                    walletName: wallet.name,
                                    coinTicker: wallet.coinTicker,
                                    coinName: wallet.coinName,
                                    id: wallet.id
                                }
                            });

                            setWallet(resWalletData)
                        }

                        if (typeof res[4].statusCode && res[4].statusCode === 400) {
                            return
                        } else {
                            setHistory(res[4]);
                        }
                    }
                } catch (e) {
                    console.log(`Catch The Error :::`, e)
                    return toastError('Failed to get initial value. Please refresh and try again');
                } finally {
                    setLoading(false);
                }
            })()
        }, [firstVisit]);


        const fetchingCertificateAndProject = useCallback(async () => {
            try {

                const certificate = await API.get(API_ARGOS_ID, `/certificate?alias=${env}&userId=${user.id}`, {});

                const [authHistory, wallet] = await Promise.all([
                    API.get(API_ARGOS_ID, `/confirmation?alias=${env}&argosId=${certificate.argosId}&userId=${user.id}&requestType=getAuthHistory`, {}),
                    API.post(API_ARGOS_ID, '/wallet', {
                        body: {
                            userId: user.id,
                            alias: env,
                            requestType: 'get',
                        }
                    }),
                ])
                setUserEmail(certificate?.Item?.email)
                return [certificate, , wallet, , authHistory]
            } catch (e) {
                return toastError("Fetching Fail , Please refresh and try again")
            }
        }, [user])


        const animationTrigger = useCallback(() => {
            setNextPageAnimation(false);
            setPrevPageAnimation(false);
        }, [])


        const openLiveForm = () => {
            flushSync(() => {
                setStep((prev) => prev + 1);
            })



            const socketUid = uniqid();

            const liveFormURL = env === 'live' ? "https://form.argoskyc.com/" : "https://dev.d21afmipicwyox.amplifyapp.com/"
            const pid = env === 'live' ? 'nlrco4vxga' : '3yrf8mf8o6';

            const win = window.open(`${liveFormURL}index?pid=${pid}&aiduserId=${user.id}&aidProjectId=${pid}&uid=${socketUid}`, 'Secure Payment');
            setLoading(true);
            const timer = setInterval(async () => {
                if (win && win.closed) {
                    clearInterval(timer);
                    try {
                        const {
                            idStatus,
                            argosId
                        }: ICertificate = await API.get(API_ARGOS_ID, `/certificate?alias=${env}&userId=${user.id}`, {});
                        setLiveFormResult(idStatus)
                        setUser((prev) => {
                            if (argosId) {
                                return ({...prev, argosId})
                            }
                            return prev
                        })

                        if (idStatus === 'not approved') {
                            setPrevPageAnimation(true)
                            setStep(prev => prev - 1)
                            return toastError('Not Approved, Retry')
                        }
                    } catch (e) {
                        console.log("Catch the Error open Live form ::: ", e)
                    } finally {
                        setLoading(false)
                    }
                }
            }, 50);
            return () => clearInterval(timer)
        }


        const goNextPage = async () => {
            setNextPageAnimation(true)

            if (liveFormResult === IIdStatus.notApproved || liveFormResult === IIdStatus.rejected && step === 0) {
                return openLiveForm()
            }

            if (liveFormResult === 'approved' && step === 0) {
                return setStep((prev) => prev + 2);
            }

            setStep((prev) => prev + 1)
        }

        const changeSubRouter = useCallback((subRouter: ISubRouter) => {
            if (subRouter === '') {
                setPrevPageAnimation(true)
                return setSubRouter(subRouter);
            }

            setNextPageAnimation(true)
            setSubRouter(subRouter);
        }, [subRouter])




        return (

            <ModalWithChildren
                setUser={setUser}
                animationTrigger={animationTrigger}
                nextPageAnimation={nextPageAnimation}
                prevPageAnimation={prevPageAnimation}
                setRouter={setRouter}
                router={router}
                liveFormResult={liveFormResult}
                setSubRouter={setSubRouter}
            >
                {
                    router === "firstVisit" &&
                    <ConsentForm
                        setFirstVisit={setFirstVisit}
                        setRouter={setRouter}
                        user={user}
                        env={env}
                    />
                }
                {
                    router === 'user' &&
                    <User
                        user={user}
                        setUser={setUser}
                        loading={loading}
                        setLoading={setLoading}
                        liveFormResult={liveFormResult}
                        setLiveFormResult={setLiveFormResult}
                        submission={submission}
                        subRouter={subRouter}
                        router={router}
                        changeSubRouter={changeSubRouter}
                        setSubmission={setSubmission}
                        env={env}
                    />
                }
                {
                    router === 'wallet' &&
                    <Walletes
                        goNextStep={goNextPage}
                        liveFormResult={liveFormResult}
                        step={step}
                        alreadyUserId={alreadyUserId}
                        user={user}
                        loading={loading}
                        wallet={wallet}
                        setWallet={setWallet}
                        setNextPageAnimation={setNextPageAnimation}
                        currentSelectNetwork={currentSelectNetwork}
                        setCurrentSelectNetwork={setCurrentSelectNetwork}
                        setRouter={setRouter}
                        env={env}
                    />
                }
                {
                    router === 'setting' &&
                    <Setting
                        user={user}
                        setUser={setUser}
                        history={history}
                        subRouter={subRouter}
                        changeSubRouter={changeSubRouter}
                        setRouter={setRouter}
                        setSubmission={setSubmission}
                        userEmail={userEmail}
                        env={env}
                    />
                }
                {
                    router === 'changeNetwork' &&
                    <ChangeNetwork
                        setPrevPageAnimation={setPrevPageAnimation}
                        setRouter={setRouter}
                        currentSelectNetwork={currentSelectNetwork}
                        setCurrentSelectNetwork={setCurrentSelectNetwork}
                        env={env}
                    />
                }
                <ToastContainer/>
            </ModalWithChildren>


        )
    }

export default ArgosId