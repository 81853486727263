import React, {Dispatch, PropsWithChildren, ReactNode, SetStateAction} from 'react';
import Header from "./Header";
import Modal from "react-modal";
import {IIdStatus, IMainRouter, ISubRouter, IUser} from "../../types/type";


const customStyles = {
    content: {
        top: '50%',
        width: '100%',
        height: '100%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "rgba(0,0,0,0.6)",
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center'
    },
};

interface IModalWithChildren {
    setUser: Dispatch<SetStateAction<IUser>>
    animationTrigger: () => void;
    nextPageAnimation: boolean,
    prevPageAnimation: boolean,
    setRouter: Dispatch<SetStateAction<IMainRouter>>
    router: IMainRouter
    liveFormResult: IIdStatus | null
    setSubRouter: React.Dispatch<React.SetStateAction<ISubRouter>>
}


const ModalWithChildren: React.FC<React.PropsWithChildren<IModalWithChildren>> =
    ({
         children,
         setUser,
         animationTrigger,
         nextPageAnimation,
         prevPageAnimation,
         setRouter,
         router,
         liveFormResult,
         setSubRouter,
     }) => {
        return (
            <Modal
                portalClassName={'argos-central-modal'}
                isOpen={true}
                style={customStyles}
                contentLabel="ARGOS ID Modal"
                ariaHideApp={false}
            >
                <main className={'flex flex-col items-center justify-center h-auto bg-white overflow-hidden'} style={{'width':'365px'}}>

                    <Header
                        setUser={setUser}
                        setRouter={setRouter}
                        router={router}
                        liveFormResult={liveFormResult}
                        setSubRouter={setSubRouter}
                    />
                    <section
                        onAnimationEnd={animationTrigger}
                        className={`w-full p-5 will-change-transform
                    ${nextPageAnimation ? ' wrapper-slide-in' : ''}
                    ${prevPageAnimation ? ' wrapper-slide-out' : ''}`}>
                        {children}
                    </section>
                </main>
            </Modal>
        )
    };

export default ModalWithChildren