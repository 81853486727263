import React from 'react';
import {ISettingElement, ISettingElementItem} from "../types/type";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {ISubRouter} from "../types/type";
import {useTranslation} from "react-i18next";
import Toggle  from "./common/Toggle";

interface IEachSettingList {
    setting: ISettingElement
    changeSubRouter: (subRouter: ISubRouter) => void;
}


const EachSettingList: React.FC<IEachSettingList> =
    ({
         setting,
         changeSubRouter,
     }) => {

        return (
            <li className={'border-b-2 pb-2 border-gray-200 '}>
                <h5 className={'text-blue-400 text-xxs mt-2'}>{setting.title}</h5>
                <ul className={'flex flex-col w-full gap-y-1'}>
                    {
                        setting.item.map((item) => {
                            const clickAction = (item: ISettingElementItem) => {
                                if (item.type === "subRouter") {
                                    return changeSubRouter(item.subRouter)
                                }
                                return window.open(item.href, "_blank");

                            }

                            return (
                                <li className={'flex items-center justify-between cursor-pointer hover:font-bold text-xs'}
                                    key={item.value}
                                    onClick={() => clickAction(item)}
                                >
                                    <span>{item.value}</span>
                                    <span>


                                        {/*{item.value === t("ReceiveAdvertisingInformation") ?*/}
                                        {/*    <Toggle checked={toggleState} onChange={setToggleState}/> :*/}
                                        {/*    <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>*/}
                                        {/*}*/}


                                        {item.type === 'link' ?
                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                            :
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        }
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
            </li>

        )
    }

export default React.memo(EachSettingList);