import React from 'react';
import chrome from '../assets/chrome.svg';
import brave from '../assets/brave.svg';
import firefox from '../assets/firerfox.svg';
import edge from '../assets/edge.svg';

const NotSupportedWalletsList = () => {
    return (
        <article className={'flex flex-col w-full '}>
            <p>The browser you are using is NOT supported at the moment.</p>
            <p>Please connect your wallet through the following supported browsers.'PC'</p>
            <article className={'flex gap-7 mt-4 '}>
                <div className={'flex flex-col justify-center items-center'} style={{'width':'50px'}}>
                    <img src={chrome} alt={"chrome Icon"} width={'50px'}/>
                    <span className={'mt-1.5'}>Chrome</span>
                </div>
                <div className={'flex flex-col justify-center items-center'} style={{'width':'50px'}}>
                    <img src={brave} alt={"brave Icon"} width={'50px'}/>
                    <span className={'mt-1.5'}>Brave</span>
                </div>
                <div className={'flex flex-col justify-center items-center'} style={{'width':'50px'}}>
                    <img src={firefox} alt={"chrome Icon"} width={'50px'}/>
                    <span className={'mt-1.5'}>Firefox</span>
                </div>
                <div className={'flex flex-col justify-center items-center'} style={{'width':'50px'}}>
                    <img src={edge} alt={"chrome Icon"} width={'50px'}/>
                    <span className={'mt-1.5'}>Edge</span>
                </div>
            </article>
        </article>

    )
}
export default NotSupportedWalletsList