import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {API} from "aws-amplify";
import {API_ARGOS_ID} from "../../constant";
import {IMainRouter, IUser} from "../../types/type";

import {toastError, toastSuccess} from "../../toast";
import Button from "../../components/common/Button";
import {useTranslation} from "react-i18next";

interface IDeleteArgosId {
    user: IUser
    setUser: Dispatch<SetStateAction<IUser>>
    setRouter: Dispatch<SetStateAction<IMainRouter>>
    setSubmission: Dispatch<SetStateAction<any>>
    userEmail: string
    env : "live" | "dev"
}

const DeleteArgosId: React.FC<IDeleteArgosId> = ({user, setUser, setRouter, setSubmission, userEmail,env}) => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const deleteArgosId = useCallback(async () => {
        try {
            setLoading(prev => !prev)
            const deleteResponse = await API.get(API_ARGOS_ID, `/certificate?alias=${env}&requestType=deleteCert&userId=${user.id}&uuid=${user.uuid}`, {});
            if (deleteResponse.statusCode !== 200) {
                return toastError('Somethings Wrong, Retry Again')
            }
            toastSuccess(deleteResponse.message)
            setSubmission(null);
            setUser((prev) => ({
                ...prev,
                objectId: "",
                id: "",
                className: "",
                argosId: "",
                uuid: '',
                consentMarketing: false
            }))
            const redirect = setTimeout(() => {
                clearTimeout(redirect)
                return setRouter('user')
            }, 1000)
        } catch (e) {
            return toastError('Somethings Wrong , Retry Again');
        } finally {
            setLoading(prev => !prev)
        }
    }, [])



    return (
        <section className={'flex flex-col'}>

            <h2 className={'text-center text-xl'}>{t("delete-argosId")}</h2>
            <article className={'flex flex-col justify-center mt-4 text-center text-xxs font-light'}>
                <p>{t('delete-argos-1')}</p>
                <p>{t('delete-argos-2')}</p>
                <p>{t('delete-argos-3')}</p>
            </article>
            <input
                className="border-gray-400 border-2 rounded-md  p-2 mt-6"
                placeholder={`${userEmail}`}
                onChange={(e) => {
                    if (e.target.value === userEmail) {
                        setIsDelete(true)
                        console.log(isDelete)
                    } else {
                        setIsDelete(false)
                        console.log(isDelete)
                    }
                }}
            />
            <Button disabled={!isDelete} onClick={deleteArgosId} value={`${t("delete")}`} theme={'danger'}/>
        </section>
    )
}
export default DeleteArgosId