import React, {useCallback, useEffect} from 'react';
import {IHistory} from "../../types/type";
import {useTranslation} from "react-i18next";
import {parseISO} from "date-fns";
import {format, utcToZonedTime} from "date-fns-tz";

interface IAuthHistory {
    history: IHistory[]
    argosId?: string
}

const AuthHistory: React.FC<IAuthHistory> =
    ({
         history,
         argosId
     }) => {

        const convertDate = useCallback((date: string) => {
            const convert = date.replace("T", " ").slice(0, -8)
            return convert
        },[])

        const {t} = useTranslation()
        return (
            <section className={'flex flex-col text-xs'}>
                <table className="table-auto">
                    <thead>
                    <tr>
                        <th>{t("company")}</th>
                        {/*<th>Argos ID</th>*/}
                        <th>{t("time")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        history.map((history, index) => {
                            return (
                                <tr className={'text-center'} key={Date.parse(history.authTime) + index}>
                                    <td>{history.projectName}</td>
                                    <td>{convertDate(history.authTime)}</td>
                                </tr>
                            )
                        })
                    }


                    </tbody>
                </table>
            </section>
        )
    }
export default AuthHistory