import React, {useLayoutEffect, useState} from 'react';
import SignIn from "./pages/SignIn";
import ArgosId from "./pages/ArgosId";
import {IUser} from "./types/type";
import i18n from "i18next";

const App = ({env} : {env : 'dev' | 'live'}) => {
    useLayoutEffect(() => {
        const locale = navigator.language;
        i18n.changeLanguage('en-US')
        if (locale === 'ko-KR') {
            i18n.changeLanguage(locale)
        } else if (locale == 'en-US') {
            i18n.changeLanguage(locale)
        }
    },[])

    const [user, setUser] = useState<IUser>({
        objectId : "",
        id : "",
        className : "",
        argosId : "",
        uuid : '',
        consentMarketing : false,
    })
    const [alreadyUserId , setAlreadyUserId] = useState<boolean>(false);
    const [firstVisit , setFirstVisit] = useState<boolean>(false);



    if (user.objectId && user.id) {
        return (
            <ArgosId
                alreadyUserId={alreadyUserId}
                user={user}
                setUser={setUser}
                firstVisit={firstVisit}
                setFirstVisit={setFirstVisit}
                env={env}
            />
        )
    }

    return (
        <SignIn
            setAlreadyUserId={setAlreadyUserId}
            setUser={setUser}
            setFirstVisit={setFirstVisit}
            env={env}
        />
    )
}


export default App;