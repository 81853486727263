import React from 'react';


interface ILanguage {

}


const Language : React.FC<ILanguage> =
    ({

     }) => {
    return (
        <section className={'flex flex-col text-xs'}>
            <span>Language</span>
        </section>
    )
}
export default Language;