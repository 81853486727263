import {CSSProperties, memo} from "react";
import {MoonLoader} from "react-spinners";


const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

const overlay: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    zIndex: 1000
}

const RingsLoader = () => {
    return (
        <section className={'bg-white'} style={{minHeight : '345px'}}>
            <div className="sweet-loading" style={overlay}>
                <MoonLoader cssOverride={override} size={75}/>
            </div>
        </section>
    )
}


const RingsLoaderMemo = memo(RingsLoader);
export default RingsLoaderMemo;

