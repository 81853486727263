import {useMemo} from "react";
import {ISettingElement} from "../types/type";
import i18n from "../lang/i18n";


interface ITranslateObject  {
    title : string
    items : string[]
}



export function useTranslationText(primitiveArray : ISettingElement[], translateArray : ITranslateObject[]) {
    const translateResult =  useMemo(() => {
        return primitiveArray.map((element , index) => {
            return {
                ...element,
                title : translateArray[index].title,
                item : translateArray[index].items.map((itemsItem , itemIndex) => {
                    return {
                        ...element.item[itemIndex],
                        value : translateArray[index].items[itemIndex]
                    }
                })
            }
        })
    },[i18n.language]);

    return [translateResult];
}