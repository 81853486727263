import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {ICertificate, IIdStatus, IMainRouter, ISubRouter, IUser} from "../../types/type";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRotateLeft,
    faCircleCheck,
    faCircleExclamation,
    faCircleMinus,
    faCirclePlus,
    faCircleUser,
    faHome,
    faIdCard,
    faEye,
    faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import {API} from "aws-amplify";
import RingsLoaderMemo from "../../components/common/Loader";
import {toastError} from "../../toast";
import {flushSync} from "react-dom";
import {format} from 'date-fns'
import Face from "./Face";
import Id from "./Id";
import {API_ARGOS_ID} from "../../constant";
import SubRouterHeader from "../../components/common/SubRouterHeader";
import {useTranslation} from "react-i18next";
import uniqid from "uniqid";
import argosLogo from "../../assets/argosLogo.png";

import getCountryName from "../../lib/getCountryName";

interface IHome {
    user?: IUser
    setUser: Dispatch<SetStateAction<IUser>>
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    liveFormResult: IIdStatus | null
    setLiveFormResult: Dispatch<SetStateAction<IIdStatus | null>>
    submission?: any
    subRouter: ISubRouter
    changeSubRouter: (subRouter: ISubRouter) => void;
    router: IMainRouter
    setSubmission: React.Dispatch<SetStateAction<any>>
    env : 'dev' | 'live'
}

const User: React.FC<IHome> =
    ({
         user,
         setUser,
         loading,
         setLoading,
         liveFormResult,
         setLiveFormResult,
         submission,
         subRouter,
         changeSubRouter,
         router,
         setSubmission,
        env
     }) => {




        const {t} = useTranslation()
        const [liveFormLoading, setLiveFormLoading] = useState<boolean>(false);
        const [rotate, setRotate] = useState<boolean>(true);
        const cardRef = useRef<HTMLDivElement>(null);
        const cardFront = useRef<HTMLDivElement>(null);
        const openLiveForm = () => {
            flushSync(() => setLiveFormLoading(true));
            if (liveFormLoading || !user) return;
            const socketUid = uniqid();

            const liveFormURL = env === 'live' ? "https://form.argoskyc.com/" : "https://dev.d21afmipicwyox.amplifyapp.com/"


            const pid = env === 'live' ? 'nlrco4vxga' : '3yrf8mf8o6';


            const win = window.open(`${liveFormURL}index?pid=${pid}&aiduserId=${user.id}&aidProjectId=${pid}&uid=${socketUid}`, 'Secure Payment');
            const timer = setInterval(async () => {
                if (win && win.closed) {
                    clearInterval(timer);
                    try {
                        const {
                            idStatus,
                            argosId,
                            Item
                        }: ICertificate = await API.get(API_ARGOS_ID, `/certificate?alias=${env}&userId=${user?.id}`, {});
                        if (idStatus === IIdStatus.notApproved) {
                            setLiveFormResult(idStatus)
                        }
                        if (idStatus === IIdStatus.rejected) {
                            setLiveFormResult(idStatus)
                            return toastError('Rejected , Retry Again')
                        }
                        setSubmission(Item)
                        setLiveFormResult(idStatus)
                        setUser((prev) => {
                            if (argosId) {
                                return ({...prev, argosId})
                            }
                            return prev
                        })
                    } catch (e) {
                        console.log("Catch the Error open Live form ::: ", e)
                    } finally {
                        setLiveFormLoading(false)
                    }
                }
            }, 1);
            return () => clearInterval(timer)
        }


        const [showInfo, setShowInfo] = useState<boolean>(false);



        useEffect(() => {
            setRotate(true);
        }, [router, subRouter])

        // country.js 파일 안의 value 값이랑 매칭되는지 확인 후 매칭되면 label 값을 가져옴
        // const getCountryName = (value: string) => {
        //     const countryName = country.find((item) => item.value === value);
        //     return countryName?.label;
        // }


        if (loading) {
            return <RingsLoaderMemo/>
        }
        if (subRouter === "face" && submission) {
            return (
                <>
                    <SubRouterHeader changeSubRouter={changeSubRouter}/>
                    <Face faceImage={submission.selfieImage}/>
                </>
            )
        }
        if (subRouter === 'id' && submission) {
            return (
                <>
                    <SubRouterHeader changeSubRouter={changeSubRouter}/>
                    <Id submission={submission}/>
                </>
            )
        }
        return (
            <section className={'flex flex-col'}>
                <div className={'w-full '} style={{'minHeight': '300px'}}>
                    <div
                        className={'rounded-xl w-full h-[256px] mx-auto bg-gradient-to-r p-[3px] from-[#6EE7B7] via-[#3B82F6] to-[#9333EA]'}>


                        <div
                            className={`relative flex flex-col justify-center h-full bg-white text-white rounded-lg p-4 text-black items-center ${IIdStatus.approved && ' transition-all duration-500 will-change-transform'}`}
                        >
                            {
                                liveFormResult === IIdStatus.approved && submission && (
                                    <>
                                        <article
                                            // onClick={rotateCard}
                                            className="absolute cursor-pointer rounded-xl top-0 left-0 w-full h-full flex flex-col bg-white justify-center transition-all will-change-transform z-10 p-5"
                                            >
                                            <div className={'flex flex-col w-full h-full text-base'}>
                                                <div className={'flex w-full justify-between'}>
                                                    <div className='flex items-center'>
                                                        <img src={argosLogo} style={
                                                            {
                                                                'width': '25px',
                                                                'height': 'auto'
                                                            }
                                                        }/>
                                                        <div className='ml-2'>ARGOS ID</div>
                                                    </div>


                                                    {showInfo ? <FontAwesomeIcon icon={faEye} className={'cursor-pointer'} onClick={()=> setShowInfo(false)}/> : <FontAwesomeIcon icon={faEyeSlash} className={'cursor-pointer'} onClick={() => setShowInfo(true)}/>}

                                                </div>
                                                <div className={'flex flex-col w-full text-sm gap-y-1'}>

                                                    <section className='flex w-full'>
                                                        <article className='w-1/2 p-2'>
                                                <span className={'text-left text-gray-400 w-auto'}>
                                                {t("name")}<br/>

                                                    <span className={'mt-5 text-black text-lg'}>
                                                        {showInfo ? submission?.fullName : submission?.fullName.length > 5 ? submission?.fullName?.replace(/(?<=.{1})./g, '*').replace(/(?<=.{5}).*/g, ' ***') : submission?.fullName.replace(/(?<=.{1})./g, '*')}
                                                    </span>
                                                </span>

                                                            <div
                                                                onClick={() => changeSubRouter('id')}
                                                                className={'flex gap-1 flex-1 items-center cursor-pointer text-blue-500 hover:underline mt-4'}>
                                                                <FontAwesomeIcon icon={faIdCard}/>
                                                                <span>{t("iD-document")}</span>
                                                            </div>

                                                        </article>

                                                        <article className='w-1/2 p-2'>
                                                            <div className={'text-gray-400 text-xxs'}>
                                                                {t("nationality")}<br/>
                                                                <span className={'mt-5 text-black'}>
                                                                    {getCountryName(submission?.nationality)}
                                                                    {/*{submission?.nationality}*/}
                                                                </span>
                                                            </div>

                                                            <div className='flex justify-between'>
                                                                <div className={'text-xxs text-gray-400'}>
                                                                    {t("date-of-birth")}<br/>
                                                                    {
                                                                        submission.birthDate &&

                                                                        <span
                                                                            className={'mt-5 text-black'}>{showInfo ? submission?.birthDate : submission?.birthDate.slice(0, 4) + '-' + submission?.birthDate.slice(5, 7).replace(/./g, '*') + '-' + submission?.birthDate.slice(8, 10).replace(/./g, '*')}</span>
                                                                    }

                                                                </div>
                                                                <div className={'text-xxs text-gray-400'}>
                                                                    {t("gender")}<br/>

                                                                    <span className={'mt-5 text-black'}>
                                                            {submission?.gender?.charAt(0).toUpperCase() + submission.gender?.slice(1)}</span>
                                                                </div>
                                                            </div>

                                                            {submission?.identityNumber &&
                                                                <div className={'text-xxs text-gray-400'}>
                                                                    {t("identity-number")}<br/>
                                                                    <span
                                                                        className={'mt-5 text-black'}>{showInfo ? submission?.identityNumber : submission?.identityNumber?.replace(/./g, '*')}</span>

                                                                </div>
                                                            }

                                                            <div className={'text-xxs text-gray-400 '}>
                                                                <div>
                                                                    {t("date-of-expiry")}<br/>
                                                                </div>
                                                                <span
                                                                    className={'mt-5 text-black'}>{submission?.aidExpireDate}
                                                        </span>
                                                            </div>

                                                        </article>
                                                    </section>
                                                    {
                                                        liveFormResult === IIdStatus.approved &&
                                                        <article className={'flex flex-col justify-between w-full ml-2'}>
                                                            <div className={'flex  items-center '}>
                                                                <span className={'flex text-xxs justify-center text-gray-400'}>{t("address")}</span>
                                                                {/*<FontAwesomeIcon icon={faHome} className={'text-lg'}/>*/}
                                                            </div>
                                                            {
                                                                showInfo ?
                                                                    <p className={'w-full block text-xxxs text-start'}>{`${submission?.address_input ?? ''} ${submission?.address_detail ? submission?.address_detail : ''}`}</p>
                                                                    :
                                                                    <p className={'w-full block text-xxxs text-start'}>{`${submission?.address_input ? submission?.address_input.replace(/./g, '*').slice(0, 20) : ''} ${submission?.address_detail ? submission?.address_detail.replace(/./g, '*').slice(0, 10) : ''}`}</p>
                                                            }
                                                        </article>
                                                    }

                                                </div>
                                            </div>
                                            {/*<div className='p-2'>*/}
                                            {/*    <div className={'text-xxs text-gray-400 flex '}>*/}
                                            {/*        <div>*/}
                                            {/*            {t("date-of-expiry")}<br/>*/}
                                            {/*        </div>*/}
                                            {/*        <span*/}
                                            {/*            className={'text-black ml-2'}>{submission?.aidExpireDate}*/}
                                            {/*        </span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </article>
                                    </>


                                )
                            }

                            {
                                liveFormResult === IIdStatus.notApproved &&
                                <>
                                    {liveFormLoading ?
                                        <article className={'text-xxs'}>
                                            <p>{t("kyc-processing")}</p>
                                            <p>{t("kyc-processing-2")}</p>
                                            {/*Please wait as KYC is being processed.<br/>*/}
                                            {/*You can proceed to the next step after completion.*/}
                                        </article>
                                        :
                                        <article onClick={openLiveForm}
                                                 className={'cursor-pointer flex h-full w-full items-center justify-center flex-col'}>
                                            <span className={'text-2xl'}>{t('create-argos-id')}</span>
                                            <FontAwesomeIcon icon={faCirclePlus} className={'text-5xl mt-2'}/>
                                        </article>
                                    }

                                </>
                            }
                            {
                                liveFormResult === IIdStatus.pending &&
                                <article className={'flex flex-col w-full h-full text-base'}>
                                    <span>Pending<FontAwesomeIcon icon={faCircleExclamation} className={'ml-2'}/></span>
                                    <div className={'flex flex-col items-center justify-center w-full h-full'}>
                                    <span className={'text-center text-xxs'}>
                                        {t('pending-mss-1')}<br/>
                                        {t('pending-mss-2')}
                                    </span>
                                    </div>
                                </article>
                            }
                            {
                                liveFormResult === IIdStatus.rejected &&
                                <article className={'flex flex-col w-full h-full text-base'}>
                                    <span className={'font-bold'}>Rejected<FontAwesomeIcon icon={faCircleMinus} className={'ml-2 text-rose-600'}/></span>
                                    <div className={'flex flex-col items-center justify-center w-full h-full'}
                                         onClick={openLiveForm}>
                                        <span className={'text-2xl'}>{t('create-argos-id')}</span>
                                        <FontAwesomeIcon icon={faCirclePlus} className={'text-5xl mt-2'}/>
                                    </div>
                                </article>
                            }


                        </div>



                    </div>
                    {/*address_formatted*/}
                    {/*address_detail*/}

                    {/*{*/}
                    {/*    liveFormResult === IIdStatus.approved &&*/}
                    {/*    <article className={'flex mt-3.5 items-center'}>*/}
                    {/*        <div className={'flex flex-col justify-center items-center'}>*/}
                    {/*            <span className={'flex text-xxs w-10 justify-center'}>{t("address")}</span>*/}
                    {/*            <FontAwesomeIcon icon={faHome} className={'text-lg'}/>*/}
                    {/*        </div>*/}
                    {/*        <p className={'w-full block text-xxxs text-center'}>{`${submission?.address_input ?? ''} ${submission?.address_detail ? submission?.address_detail : ''}`}</p>*/}
                    {/*    </article>*/}
                    {/*}*/}
                </div>
            </section>
        )
    }
export default User